import {
  addBusinessDays,
  addDays,
  addMonths,
  differenceInCalendarDays,
  format,
  parse as datefns_parse,
  Locale,
} from 'date-fns'
import { CommerceEnvironment } from '../constants/common'
import { DATE_FNS_LOCALE_MAP, DATE_FORMAT_PATTERN, ISO_DATE_FORMAT, COMMON_DATE_FORMATS } from '../constants/date'

type DatePattern = keyof typeof DATE_FORMAT_PATTERN

class DateService {
  isValid(date: Date | string | number): boolean {
    return date instanceof Date && !isNaN(date.getTime())
  }

  isValidFormat(date: string, format: string): boolean {
    // day between 01 and 31, month between 01 and 12, year between 1900 and 2099
    switch (format) {
      case 'MM/dd/yyyy':
        return /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/.test(date)
      case 'dd/MM/yyyy':
        return /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/.test(date)
      default:
        return true
    }
  }

  addBusinessDays(dateArg: Date | string | number, daysToAdd: number): Date | null {
    let date: Date | null = this.getValidDate(dateArg)
    if (date === null) {
      return null
    }
    date = addBusinessDays(date, daysToAdd)
    return date
  }

  addCalendarDays(dateArg: Date | string | number, daysToAdd: number): Date | null {
    let date: Date | null = this.getValidDate(dateArg)
    if (date === null) {
      return null
    }
    date = addDays(date, daysToAdd)
    return date
  }

  addMonths(dateArg: Date, monthsToAdd: number): Date | null {
    let date: Date | null = this.getValidDate(dateArg)

    return date === null ? null : addMonths(date, monthsToAdd)
  }

  getValidDate(dateArg: Date | string | number): Date | null {
    let date: number | Date

    if (this.isValid(dateArg)) {
      date = dateArg as Date
    } else {
      date = new Date(dateArg as string | number)

      if (!this.isValid(date)) {
        // Safari browser requires more stringent date parsing
        for (const dateFormat of COMMON_DATE_FORMATS) {
          date = this.parse(dateArg?.toString(), dateFormat, new Date())
          if (this.isValid(date)) {
            break
          }
        }
      }
      if (!this.isValid(date)) {
        return null
      }
    }
    return date
  }

  format(
    dateArg: Date | string | number,
    pattern: DatePattern | string = DATE_FORMAT_PATTERN['MM/dd/yyyy'],
    langCode?: string,
    optionsArg?: {
      locale?: Locale
      weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6
      firstWeekContainsDate?: number
      useAdditionalWeekYearTokens?: boolean
      useAdditionalDayOfYearTokens?: boolean
    }
  ): string {
    const date: number | Date | null = this.getValidDate(dateArg)

    if (!date || !this.isValid(date)) {
      return ''
    }

    const localeCode = langCode?.replace('-', '_') || CommerceEnvironment.defaultLang
    const localeObject = { locale: { code: DATE_FNS_LOCALE_MAP[localeCode] } }

    const options = optionsArg ? { localeObject, ...optionsArg } : { localeObject }

    return format(date, pattern, options)
  }

  parse(dateString: string, pattern: string, referenceDate: Date = new Date(1970, 0, 1, 0, 0, 0)): Date {
    return datefns_parse(dateString, pattern, referenceDate)
  }

  /**
   * Convert date string to locale date
   */
  getLocalDate(date: string, pattern: DatePattern | string = DATE_FORMAT_PATTERN['MM/dd/yyyy']): string {
    if (!date) return ''
    const localOrderDate = format(new Date(date), pattern)
    return this.convertISODate(localOrderDate.toLocaleString())
  }

  /**
   * @returns true if date string is in ISO format yyyy-MM-dd (ignores time portion)
   */
  isISODate(date: string | undefined): boolean {
    if (!date) return false

    return /^(19|20)\d{2}\-(0[1-9]|1[0-2])\-(0[1-9]|1\d|2\d|3[01])$/.test(date.substring(0, ISO_DATE_FORMAT.length))
  }

  /**
   * Convert ISO date string (yyyy-MM-dd) to a new format specified by pattern.
   * If date is not ISO format returns original dateString.
   */
  convertISODate(dateString: string, pattern: DatePattern | string = DATE_FORMAT_PATTERN['MM/dd/yyyy']): string {
    const dateToParse = dateString?.substring(0, ISO_DATE_FORMAT.length)
    if (!this.isISODate(dateToParse)) {
      return dateString
    }

    const date = datefns_parse(dateToParse, ISO_DATE_FORMAT, new Date(1970, 0, 1, 0, 0, 0))
    if (this.isValid(date)) {
      date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
    }
    return format(date, pattern)
  }

  /**
   * Get number of days elapsed from the given date
   * @param dateArg date to check
   * @returns number of days elapsed from the given date or -1 if input is not a valid date
   */
  getElapsedDays(dateArg: Date | string | number | undefined): number {
    let date: Date | number

    if (this.isValid(dateArg || '')) {
      date = dateArg as Date
    } else {
      date = new Date(dateArg as string | number)

      if (!this.isValid(date)) {
        return -1
      }
    }

    const differenceInTime = new Date().getTime() - date.getTime()
    const days = differenceInTime / (1000 * 3600 * 24)
    return days
  }

  /**
   * Get number of days from today to dateArg
   * @param dateArg to check
   * @returns number of days from today to dateArg,
   *          negative value if dateArg is in the past
   */
  getDaysToDate(dateArg: Date | string): number {
    let date: Date | number

    if (this.isValid(dateArg || '')) {
      date = dateArg as Date
    } else {
      date = new Date(dateArg as string | number)
      if (!this.isValid(date)) {
        return -1
      }
    }
    return differenceInCalendarDays(date, Date.now())
  }

  isISODateValid(start: string, end: string): boolean {
    const currentDate = new Date()
    const startDate = new Date(this.convertPriceDate(start))
    const endDate = new Date(this.convertPriceDate(end))
    return currentDate > startDate && currentDate < endDate
  }

  convertPriceDate(dateString: string | undefined): string {
    // fix for Safari https://luxotticaretail.atlassian.net/browse/DC-1751
    // & Firefox https://luxotticaretail.atlassian.net/browse/DC-2863
    if (!dateString) return ''
    let newDate: string = dateString.replaceAll('-', '/')
    let newDateList: string[] = newDate.split('.')
    if (newDateList.length > 1) newDateList[1] = newDateList[1].split(' ')[1]
    return newDateList.join(' ')
  }

  fromInvalidDateToValidDate(date: string, pattern: string): string {
    switch (pattern) {
      case 'dd-MM-yyyy':
        const splitted = date.split('-')
        return `${splitted[2]}-${splitted[1]}-${splitted[0]}`
      default:
        return date
    }
  }

  getExtendedDate(date: string, pattern: string, langCode: string): string | null {
    let d = date

    if (!this.isValid(date)) {
      d = this.fromInvalidDateToValidDate(date, pattern)
    }
    const dateFormatter = new Intl.DateTimeFormat(langCode, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    })

    return dateFormatter.format(new Date(d))
  }
}

export default new DateService()
