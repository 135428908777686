import { shallowEqual, useSelector } from 'react-redux'
import omit from 'lodash/omit'
import { CommonData } from '../analytics/tealium/interfaces'
import { RootReducerState } from '@redux/rootReducer'
import { useStoreIdentity } from './useStoreIdentity'
import {
  appliedPromotionCodesSelector,
  cartSelector,
  orderDiscountNamesSelector,
  selectedShipModeCodeSelector,
  totalOrderDiscountSelector,
} from '../../features/order/selector'
import { ordersSelector } from '@redux/selectors/account'
import { loginStatusSelector, userDetailsSelector } from '@redux/selectors/user'
import { useEffect, useState } from 'react'
import { prescriptionSelector } from '@features/prescription/selector'
import { localStorageUtil } from '@foundation/utils/storageUtil'
import { RX_PRESCRIPTION_OBJECT_KEY } from '@constants/rxConfigurator'
import { USER_SEGMENT_GUEST, USER_SEGMENT_LOGGED } from '@constants/common'
import { PersonResponse } from '@typesApp/user'
import md5 from 'crypto-js/md5.js'
import sha256 from 'crypto-js/sha256.js'
import { hostnameSelector } from '@redux/selectors/site'
import { useSite } from './useSite/useSite'
import { CART } from '@constants/routes'
import { getMinSubscriptionInterval } from '@views/Subscription/helpers/subscriptionHelpers'
import { CHECKOUT_NAMES } from '@constants/paymentMethods'
import { IOrderSliceState } from '@features/order/IOrderSliceState'
import { isReorder } from '@foundation/analytics/tealium/lib'
import { getOrderPaymentInstructionDescription } from '@foundation/analytics/tealium/formatters/productFormatter'
import { isBrowser } from '@utils/isBrowser'
import { CURRENT_USER } from '@foundation/constants/common'
import { getCustomerSegmentsUtil, useCustomerSegmentsUtil } from '@utils/Cookies'
import { IProduct } from '@typesApp/product'

export const DEFAULT_LANG = 'en-US'

export const ANALYTICS_PAGE_TYPE = {
  CART: 'cart',
  CHECKOUT_SUMMARY_AND_CONFIRMATION: 'typ',
  DELIVERY: 'Delivery',
  HOME: 'home',
  LOGIN_AND_REGISTER: 'loginAndRegister',
  NOT_FOUND: 'notfound',
  PAGE: 'page',
  PDP: 'pdp',
  PLP: 'plp',
  PAYMENT: 'Payment',
  PROFILE: 'profile',
  PROMO: 'promo',
  ORDER_SUMMARY: 'OrderSummary',
}

const TEALIUM_DATA_CART_ID = 'tealium_data_cart_id'

export const getCartIdForAnalytics = (cartId: string, sessionStorage?): string =>
  cartId ? cartId : sessionStorage.getItem(TEALIUM_DATA_CART_ID) || ''

export const getUserDetailsForAnalytics = (
  userDetails: PersonResponse | null | undefined,
  baseAnalyticsData,
  isLogged?: boolean
) => {
  let userDetail = { ...userDetails }
  baseAnalyticsData.User_LoginStatus = isLogged ? USER_SEGMENT_LOGGED : USER_SEGMENT_GUEST

  if (!!userDetail) {
    const storageUser = localStorageUtil.get(CURRENT_USER, false)
    userDetail = storageUser?.details
    if (!isLogged) {
      baseAnalyticsData.User_LoginStatus =
        storageUser && !storageUser.isGuest ? USER_SEGMENT_LOGGED : USER_SEGMENT_GUEST
    }
  }


  //if we can not collect the user details at this point, we wont be able to fill the properties
  if (!userDetail) {
    return
  }
  const country = userDetail?.country?.toUpperCase() || ''
  let formattedEmail = (userDetail?.email1 || userDetail?.logonId)?.toLowerCase()
  if (!formattedEmail && Array.isArray(userDetail?.contact)) {
    formattedEmail = userDetail?.contact[0]?.email1?.trim()
  }
  let phone = userDetail?.phone1
  if (!phone && Array.isArray(userDetail?.contact)) {
    phone = userDetail?.contact[0]?.phone1?.trim()
  }
  const md5Email = !!formattedEmail ? md5(formattedEmail).toString() : ''
  const shaEmail = !!formattedEmail ? sha256(formattedEmail).toString() : ''
  const shaPhone = phone ? sha256(phone).toString() : ''
  const md5Phone = phone ? md5(phone).toString() : ''
  baseAnalyticsData.User_Email_MD5 = md5Email
  baseAnalyticsData.User_Email_SHA256 = shaEmail

  baseAnalyticsData.User_Phone_MD5 = md5Phone
  baseAnalyticsData.User_Phone_SHA256 = shaPhone
  baseAnalyticsData.User_LoginType = 'Standard'
  baseAnalyticsData.User_Country = country
  baseAnalyticsData.User_Id = userDetail?.userId
  baseAnalyticsData.User_Segments =
    userDetail?.x_data?.customerSegments?.join(', ') || getCustomerSegmentsUtil().join(',')
  baseAnalyticsData.User_HasPrescription = !!localStorageUtil.get(RX_PRESCRIPTION_OBJECT_KEY) ? '1' : '0'
  baseAnalyticsData.User_LoyaltyCard = ''
  baseAnalyticsData.User_LoyaltyTier = ''
  baseAnalyticsData.Events_UserEmailSub = userDetail?.x_data?.hasNewsletter ? '1' : '0'
  baseAnalyticsData.User_Nation = country
}

export const useAnalyticsData = (pageType?: string): Partial<CommonData> => {
  const { country, langCode } = useStoreIdentity()
  const { mySite } = useSite()
  const order = useSelector(ordersSelector)
  const totalOrderDiscount = useSelector(totalOrderDiscountSelector)
  const orderDiscount = useSelector(orderDiscountNamesSelector)
  const orderDiscountNames = orderDiscount === undefined ? '' : orderDiscount
  const discountCode = useSelector(appliedPromotionCodesSelector)
  const discountCodes = discountCode === undefined ? '' : discountCode
  const pageServer = useSelector(hostnameSelector)
  const loginStatus = useSelector(loginStatusSelector)
  const userDetails = useSelector(userDetailsSelector)
  let userToken = ''
  let orderCartId = ''
  const cart = useSelector(cartSelector)
  const [hasPrescription, setHasPrescription] = useState<string>('0')
  const prescription = useSelector(prescriptionSelector)
  const selectedShipModeCode = useSelector(selectedShipModeCodeSelector)
  const recordCount = prescription?.recordCount || 0
  const userId = prescription?.userId

  useEffect(() => {
    userToken = getUserToken()
    orderCartId = getCartIdForAnalytics(cart?.orderId, sessionStorage)
  }, [mySite, cart?.orderId])

  useEffect(() => {
    checkCustomerRx()
  }, [loginStatus])

  /**
   * As per CLY-753 clarification, no need to call the API if the user has a saved RX in the local storage
   */
  const checkCustomerRx = async () => {
    setHasPrescription('0')
    const hasStorageRX = localStorageUtil.get(RX_PRESCRIPTION_OBJECT_KEY)
    if (hasStorageRX) {
      setHasPrescription('1')
    } else if (loginStatus && userId?.toString() === userDetails?.userId) {
      setHasPrescription(recordCount > 0 ? '1' : '0')
    }
  }

  const getCheckoutPageSection2 = (order: IOrderSliceState) => {
    if (isBrowser() && isReorder()) {
      return 'Reorder'
    }

    const paymentType = getOrderPaymentInstructionDescription(order)
    if (
      (paymentType === CHECKOUT_NAMES['APPLE_PAY'] && order?.applePayInfo?.flow === 'express') ||
      order?.paypalExpress?.isSelected
    ) {
      return 'Express'
    }

    return 'Standard'
  }

  return useSelector((state: RootReducerState) => {
    const currency = state.site.currentSite?.defaultCurrencyID
    const parsedLanguage = langCode.split('-')[0].toUpperCase()
    const parsedCountry = country.toUpperCase()

    const minSubscriptionInterval = getMinSubscriptionInterval(order?.orderItems || [])

    let baseAnalyticsData: Partial<CommonData> = {
      ...(cart?.orderId && { Page_Brand: 'CL' }),
      Page_Design: '',
      Page_Country: `${parsedCountry}`,
      Page_Language: `${parsedLanguage}`,
      Page_DeviceType: 'all',
      Page_Platform: 'WCS',
      ...(cart?.orderId && { Order_CartId: cart?.orderId }),
      ...(cart?.orderId && {
        Order_CartUrl: `${window?.location?.protocol}//${window?.location?.host}/${langCode}/${CART}`,
      }),
      Page_Server: pageServer,
      Page_Section1: 'Other',
      Page_Section2: '',
      Order_Currency: `${currency}`,
      User_LoginStatus: loginStatus ? USER_SEGMENT_LOGGED : USER_SEGMENT_GUEST,
      userToken: userToken,
      User_HasPrescription: localStorageUtil.get(RX_PRESCRIPTION_OBJECT_KEY) ? '1' : '0',
    }
    if (loginStatus) {
      baseAnalyticsData.User_LoginType = 'Standard'
    }
    if (userDetails) {
      getUserDetailsForAnalytics(userDetails, baseAnalyticsData, loginStatus)
    }
    switch (pageType) {
      case ANALYTICS_PAGE_TYPE.NOT_FOUND:
        baseAnalyticsData = omit(baseAnalyticsData, ['Order_Currency'])
        break
      case ANALYTICS_PAGE_TYPE.LOGIN_AND_REGISTER:
      case ANALYTICS_PAGE_TYPE.HOME:
        baseAnalyticsData = {
          ...baseAnalyticsData,
        }
        break
      case ANALYTICS_PAGE_TYPE.CART:
        baseAnalyticsData = {
          ...baseAnalyticsData,
          Page_Type: 'CartPage',
          Page_Section1: 'CartPage',
          Page_Section2: getCheckoutPageSection2(order),
          ...(totalOrderDiscount && totalOrderDiscount !== 'undefined' && { Order_DiscountAmount: totalOrderDiscount }),
          ...(totalOrderDiscount && totalOrderDiscount !== 'undefined' && { Order_DiscountName: orderDiscountNames }),
          ...(order?.cart?.totalProductPrice && { Order_SubTotal: order?.cart?.totalProductPrice }),
          ...(order?.cart?.totalProductPrice && { Order_Subtotal: order?.cart?.totalProductPrice }),
          ...(order?.cart?.grandTotal && { Order_Total: order?.cart?.grandTotal }),
          ...(order?.cart?.totalProductPrice && { Order_ProductsAmount: order?.cart?.totalProductPrice }),
          ...(minSubscriptionInterval > 0 && { Order_SubscriptionFrequency: `${minSubscriptionInterval}` }),
        }
        break
      case ANALYTICS_PAGE_TYPE.PROMO:
        baseAnalyticsData = {
          Order_DiscountCode: discountCodes,
          Order_DiscountName: orderDiscountNames,
        }
        break
      case ANALYTICS_PAGE_TYPE.DELIVERY:
      case ANALYTICS_PAGE_TYPE.PAYMENT:
        baseAnalyticsData = {
          ...baseAnalyticsData,
          Page_Type: 'Checkout',
          Page_Section1: 'Checkout',
          Page_Section2: getCheckoutPageSection2(order),
          Order_DiscountAmount: totalOrderDiscount,
          Order_DiscountName: orderDiscountNames,
          Order_DiscountCode: discountCodes,
          Order_ShippingMode: order?.orderItems?.[0]?.shipModeCode || '',
          Order_SubTotal: order?.cart?.totalProductPrice,
          Order_Subtotal: order?.cart?.totalProductPrice,
          Order_Total: order?.cart?.grandTotal,
          Order_ProductsAmount: order?.cart?.totalProductPrice,
          Order_QuotId: order?.cart?.orderId,
          Order_ShippingDeliveryDate: order?.orderItems?.[0]?.expectedShipDate || '',
          ...(minSubscriptionInterval > 0 && { Order_SubscriptionFrequency: `${minSubscriptionInterval}` }),
        }
        break
      case ANALYTICS_PAGE_TYPE.CHECKOUT_SUMMARY_AND_CONFIRMATION:
        baseAnalyticsData = {
          ...baseAnalyticsData,
          Events_UserEmailSub: '1',
          Page_Type: 'Thankyou',
          Page_Section1: 'Checkout',
          Page_Section2: getCheckoutPageSection2(order),
          Order_DiscountAmount: totalOrderDiscount,
          Order_DiscountName: orderDiscountNames,
          Order_DiscountCode: discountCodes,
          Order_ShippingMode: order?.orderItems?.[0]?.shipModeCode || '',
          ...(minSubscriptionInterval > 0 && { Order_SubscriptionFrequency: `${minSubscriptionInterval}` }),
        }
        break
      case ANALYTICS_PAGE_TYPE.ORDER_SUMMARY:
        baseAnalyticsData = {
          ...baseAnalyticsData,
          Page_Type: 'OrderSummary',
          Page_Section1: 'Checkout',
          Page_Section2: getCheckoutPageSection2(order),
          Order_DiscountAmount: totalOrderDiscount,
          Order_DiscountName: orderDiscountNames,
          Order_DiscountCode: discountCodes,
          Order_ShippingMode: order?.orderItems?.[0]?.shipModeCode || '',
          ...(minSubscriptionInterval > 0 && { Order_SubscriptionFrequency: `${minSubscriptionInterval}` }),
        }
        break
      case ANALYTICS_PAGE_TYPE.PAGE:
        baseAnalyticsData = {
          ...baseAnalyticsData,
        }
        break
      case ANALYTICS_PAGE_TYPE.PLP:
      case ANALYTICS_PAGE_TYPE.PDP:
      case ANALYTICS_PAGE_TYPE.PROFILE:
      default:
    }
    return baseAnalyticsData
  }, shallowEqual)
}

export const getDiscount = (
  listPrice: number,
  offerPrice: number,
  customerSegment: string
): { amount: string; customerSegment: string } => ({
  amount: offerPrice < listPrice ? (listPrice - offerPrice).toFixed(2) : '0.00',
  customerSegment,
})

export const getUserToken = () => {
  const hasWindowElement = typeof window !== 'undefined'
  const hasSessioStorage = typeof sessionStorage !== 'undefined'
  let savedUserToken: any = null
  let utagUserToken: any = null
  const randomUserToken = `${Math.floor(Math.random() * 100000)}${new Date().getTime()}`

  if (hasSessioStorage) {
    savedUserToken = sessionStorage?.getItem('userToken')
  }
  if (hasWindowElement) {
    utagUserToken = window?.utag_data?.User_Email_MD5
  }

  const userToken = utagUserToken || savedUserToken || randomUserToken

  if (hasSessioStorage) {
    sessionStorage.setItem('userToken', userToken)
  }

  return userToken
}
