import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { OrderItem } from '../../types/order'
import { RootReducerState } from '@redux/rootReducer'
import { orderApi } from '@features/order/query'
import { LOGOUT_REQUESTED_ACTION, LOGOUT_SUCCESS_ACTION } from '@redux/actions/user'
import { CART } from '@constants/routes'
import { CHECKOUT } from '@constants/common'
import { ServerErrorResponse } from '@typesApp/common'
import { PrescriptionFormData } from '@typesApp/prescription'
import { clearPrescriptionLens } from '@features/rox/roxSlice'

type StartRemovingContactLensPayload = {
  prescriptionDetails?: PrescriptionFormData
}


interface RemoveCLEyeStatus {
  orderItemId: string | undefined
  left: boolean
  right: boolean
}

export interface CartUIState {
  isAddToCartBusy: boolean
  isEditingItem: boolean
  isRemovingItem: boolean
  removeItemId?: string
  isUndoRemove?: boolean
  removedItem?: OrderItem
  removeCLEye: RemoveCLEyeStatus
  forceRefetch?: boolean
  forceBusyCart?: boolean
  cartErrorBanner?: ServerErrorResponse
  prescriptionCache?: StartRemovingContactLensPayload
}

const initialState: CartUIState = {
  isAddToCartBusy: false,
  isEditingItem: false,
  isRemovingItem: false,
  removeCLEye: { orderItemId: '', left: false, right: false },
}

export const cartuiSliceInitialState = (): CartUIState => initialState

export const cartuiSlice = createSlice({
  name: 'cartui',
  initialState,
  reducers: {
    resetCartUI: () => {
      return initialState
    },
    setAddToCartBusy: (state, action: PayloadAction<boolean>) => {
      state.isAddToCartBusy = action.payload
    },
    startEditingCartItem: state => {
      state.isEditingItem = true
    },
    doneEditingCartItem: state => {
      state.isEditingItem = false
    },
    startRemovingContactLens: (state, action: PayloadAction<StartRemovingContactLensPayload>) => {
      state.prescriptionCache = action.payload
    },
    clearPrescriptionCache: state => {
      state.prescriptionCache = undefined
    },
    startRemovingCartItem: (state, action: PayloadAction<string>) => {
      state.isRemovingItem = true
      state.removeItemId = action.payload
    },
    doneRemovingCartItem: (state, action: PayloadAction<OrderItem | undefined>) => {
      state.isRemovingItem = false
      state.removeItemId = undefined
      state.removedItem = action?.payload
      state.removeCLEye = { orderItemId: action?.payload?.orderItemId, left: false, right: false }
    },
    startUndoRemoveCartItem: state => {
      state.isUndoRemove = true
    },
    doneUndoRemoveCartItem: state => {
      state.isUndoRemove = false
      state.removedItem = undefined
    },
    startRemovingCLEye: (state, action: PayloadAction<RemoveCLEyeStatus>) => {
      state.removeCLEye = action.payload
    },
    setCartErrorBanner: (state, action: PayloadAction<ServerErrorResponse>) => {
      state.cartErrorBanner = action.payload
    },
  },
  extraReducers: builder => {
    /**
     * Force busy state on cart on logout request
     */
    builder.addCase(LOGOUT_REQUESTED_ACTION, state => {
      const isCartOrCheckout = window.location.pathname.includes(CART) || window.location.pathname.includes(CHECKOUT)
      state.forceBusyCart = isCartOrCheckout
      if (!state.forceBusyCart && isCartOrCheckout) {
        state.forceBusyCart = true
      }
    })

    /**
     * Force cart refresh after logout from cart or checkout.
     * This needs to be done on LOGOUT_SUCCESS_ACTION so that
     * B/E has completed the cleanup of insurance coverage on the order.
     */
    builder.addCase(LOGOUT_SUCCESS_ACTION, state => {
      const isCartOrCheckout = window.location.pathname.includes(CART) || window.location.pathname.includes(CHECKOUT)
      if (!state.forceRefetch && isCartOrCheckout) {
        state.forceRefetch = true
      }
    })

    builder.addMatcher(
      isAnyOf(orderApi.endpoints.getCart.matchFulfilled, orderApi.endpoints.getCart.matchRejected),
      state => {
        state.forceRefetch = undefined
        state.forceBusyCart = undefined
      }
    )
  },
})

export const {
  resetCartUI,
  setAddToCartBusy,
  startEditingCartItem,
  doneEditingCartItem,
  startRemovingCartItem,
  doneRemovingCartItem,
  startUndoRemoveCartItem,
  doneUndoRemoveCartItem,
  startRemovingCLEye,
  setCartErrorBanner,
  startRemovingContactLens,
  clearPrescriptionCache,
} = cartuiSlice.actions

export const addToCartBusySelector = (state: RootReducerState): boolean => !!state.cartui?.isAddToCartBusy
export const editingCartItemSelector = (state: RootReducerState): boolean => !!state.cartui?.isEditingItem
export const removingCartItemSelector = (state: RootReducerState): boolean => !!state.cartui?.isRemovingItem
export const removeCartItemIdSelector = (state: RootReducerState): string | undefined => state.cartui?.removeItemId
export const undoingRemoveCartItemSelector = (state: RootReducerState): boolean => !!state.cartui?.isUndoRemove
export const lastRemovedCartItemSelector = (state: RootReducerState): OrderItem | undefined => state.cartui?.removedItem
export const removingCLEyeSelector = (state: RootReducerState): RemoveCLEyeStatus => state.cartui?.removeCLEye
export const forceRefetchCartSelector = (state: RootReducerState): boolean => !!state.cartui?.forceRefetch === true
export const forceBusyCartSelector = (state: RootReducerState): boolean => !!state.cartui?.forceBusyCart === true
export const cartErrorBannerSelector = (state: RootReducerState): any => state.cartui?.cartErrorBanner
export const prescriptionCacheSelector = (state: RootReducerState) => state.cartui?.prescriptionCache
