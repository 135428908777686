import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import { NOT_FOUND } from 'http-status-codes'
import { HYPHEN, ORDER_ID } from '../../constants/common'
import {
  ESTIMATED_TAXES,
  INVENTORY_STATUS,
  ORDER_STATUS,
  RX_PRODUCTS_IN_CART_LIMIT,
  TAXES_ZIP_CODE,
  getOrderItemsAttachments,
} from '../../constants/order'
import { ACCOUNT } from '../../foundation/constants/common'
import { localStorageUtil, sessionStorageUtil } from '../../foundation/utils/storageUtil'

import { PaymentInfoType, PrescriptionMacroGroup } from '../../types/checkout'
import {
  Cart,
  IOrderDetails,
  OrderItem,
  ShippingChargesWithoutPromotions,
  OrderItemWithRoxProps,
  UsableShippingMode,
  IReorderInfo,
  ApplePayFlow,
} from '../../types/order'
import { isRxProduct } from '../../utils/isRxOrder'
import { logout } from '../../features/common/slice'
import { IOrderSliceState } from './IOrderSliceState'
import {
  addContactLenses,
  addItem,
  fetchCart,
  fetchPayMethods,
  fetchPaypalExpress,
  fetchShipInfo,
  finalizeOrder,
} from './thunks'
import { addFrame, addLens, addRXCItem } from './thunks/addLensItem'
import { orderApi } from './query'
import {
  getPrescriptionItemsMap,
  orderHasPrescriptionUploaded,
  orderHasPrescriptionPeriodExcedeed,
  getOrderItemsMap,
  isOrderComplete,
  getCartProductCount,
  getShipModeWithLongestLeadTime,
} from '../../utils/order'
import { PrescriptionFormData } from '../../types/prescription'
import { SET_SUBSCRIPTION_ORDER_COMPLETE_ACTION } from '@redux/actions/subscription'

const initialState: IOrderSliceState = {
  applePayInfo: {
    flow: 'classic',
  },
  isFetching: false,
  isFetchingOrderDetails: false,
  isFetchingShippingInfo: false,
  isAddingItem: false,
  billingFormStatus: {
    isValid: false,
  },
  isShippingUsedAsBilling: false,
  isCheckoutDisabled: false,
  isFinalizationFailed: false,
  isFinalizing: false,
  isRXProductsLimitExceeded: false,
  isRecurringOrderDisabled: false,
  isPromoCouponApplied: false,
  numItems: 0,
  orderComplete: false,
  orderItems: [],
  parsedOrderItems: {
    rx: null,
    cl: null,
    'cl-acc': null,
    sun: null,
    default: null,
  },
  payMethods: [],
  paypalExpress: {
    isSelected: false,
    error: false,
    loading: false,
  },
  selectedPayMethodInfoList: [],
  shippingFormStatus: {
    isValid: false,
  },
  orderDetails: null,
  usePrescription: {
    selectedMacroIndex: 0,
    prescriptionFormData: {},
    prescriptionMacroGroups: [],
  },
  creditCardNumber: null,
  cartShippingCharges: {
    shippingCharges: null,
    error: null,
  },
}

export type OrderApiState = Partial<IOrderSliceState>

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    updateCart: (state, action: PayloadAction<any>) => {
      state.cart = action.payload
    },
    resetCart: () => {
      //! SessionStorageUtil shouldn't be used inside a reducer
      sessionStorageUtil.remove(ESTIMATED_TAXES)
      sessionStorageUtil.remove(TAXES_ZIP_CODE)
      return initialState
    },

    toggleCartOrderComplete: (state, action: PayloadAction<boolean>) => {
      state.orderComplete = action.payload
      sessionStorageUtil.remove(ESTIMATED_TAXES)
      sessionStorageUtil.remove(TAXES_ZIP_CODE)
    },

    toggleShippingAsBilling: (state, action: PayloadAction<boolean>) => {
      state.isShippingUsedAsBilling = action.payload
    },

    togglePaypalExpress: (state, action: PayloadAction<boolean>) => {
      state.paypalExpress = {
        ...state.paypalExpress,
        isSelected: action.payload,
      }
    },

    setBillingFormValidStatus: (state, { payload }: PayloadAction<boolean>) => {
      state.billingFormStatus = {
        ...state.billingFormStatus,
        isValid: payload,
      }
    },

    setPaymentMethod: (state, { payload }: PayloadAction<PaymentInfoType[]>) => {
      state.selectedPayMethodInfoList = payload
    },

    setIsPromoCouponApplied: (state, { payload }: PayloadAction<boolean>) => {
      state.isPromoCouponApplied = payload
    },

    setShippingFormValidStatus: (state, { payload }: PayloadAction<boolean>) => {
      state.shippingFormStatus = {
        ...state.shippingFormStatus,
        isValid: payload,
      }
    },
    toggleSameUserItemPrescription: (
      state,
      {
        payload,
      }: PayloadAction<{
        prescriptionGroupIndex: number
        value: boolean
      }>
    ) => {
      state.usePrescription.prescriptionMacroGroups[payload.prescriptionGroupIndex].isSamePrescriptionSelected =
        payload.value
    },
    setUserPrescriptionsGroups: (state, { payload }: PayloadAction<PrescriptionMacroGroup[]>) => {
      state.usePrescription.prescriptionMacroGroups = payload
    },
    setSelectedPrescriptionGroupIndex: (state, { payload }: PayloadAction<number>) => {
      state.usePrescription.selectedMacroIndex = payload
    },
    setSelectedItemIndex: (state, { payload }: PayloadAction<{ prescriptionGroupIndex: number; value: number }>) => {
      if (state.usePrescription.prescriptionMacroGroups[payload.prescriptionGroupIndex]) {
        state.usePrescription.prescriptionMacroGroups[payload.prescriptionGroupIndex].selectedItemIndex = payload.value
      }
    },
    setUserPrescriptionGroupActive: (state, { payload }: PayloadAction<number>) => {
      state.usePrescription.prescriptionMacroGroups = state.usePrescription.prescriptionMacroGroups.map((group, i) => {
        return {
          ...group,
          active: payload === i,
        }
      })
    },
    setUserPrescriptionFormData: (
      state,
      {
        payload,
      }: PayloadAction<{
        value: PrescriptionFormData
      }>
    ) => {
      state.usePrescription.prescriptionFormData = payload.value
    },
    setUserPrescriptionItemSkipped: (
      state,
      {
        payload,
      }: PayloadAction<{
        prescriptionGroupIndex: number
        prescriptionItemIndex: number
      }>
    ) => {
      const { prescriptionGroupIndex, prescriptionItemIndex } = payload
      const selectedPrescriptionMacroGroup = state.usePrescription.prescriptionMacroGroups[prescriptionGroupIndex]
      const { skippedrPrescriptionItems } = selectedPrescriptionMacroGroup
      skippedrPrescriptionItems.push(prescriptionItemIndex)
      selectedPrescriptionMacroGroup.skippedrPrescriptionItems = skippedrPrescriptionItems
    },
    setUserPrescriptionItemCompleted: (
      state,
      {
        payload,
      }: PayloadAction<{
        prescriptionGroupIndex: number
        prescriptionItemIndex: number
      }>
    ) => {
      const { prescriptionGroupIndex, prescriptionItemIndex } = payload
      const selectedPrescriptionMacroGroup = state.usePrescription.prescriptionMacroGroups[prescriptionGroupIndex]
      const { completedPrescriptionItems } = selectedPrescriptionMacroGroup
      completedPrescriptionItems.push(prescriptionItemIndex)
      selectedPrescriptionMacroGroup.completedPrescriptionItems = completedPrescriptionItems
    },
    setCreditCardNumber: (state, { payload }: PayloadAction<string>) => {
      state.creditCardNumber = payload
    },
    resetCreditCardNumber: state => {
      state.creditCardNumber = initialState.creditCardNumber
    },
    setReorderId: (state, action: PayloadAction<string>) => {
      state.reorderId = action.payload
    },
    resetReorderId: state => {
      state.reorderId = initialState.reorderId
    },
    setReorderInfo: (state, action: PayloadAction<IReorderInfo>) => {
      state.reorderInfo = action.payload
    },
    resetReorderInfo: state => {
      state.reorderInfo = initialState.reorderInfo
    },
    isPromoCouponApplied: state => {
      state.isPromoCouponApplied = initialState.isPromoCouponApplied
    },
    setApplePayFlow: (state, action: PayloadAction<ApplePayFlow>) => {
      state.applePayInfo.flow = action.payload
    },
    setSelectedShipModeCode: (state, action: PayloadAction<string>) => {
      state.selectedShipModeCode = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(logout, () => initialState)

    builder.addCase(addItem.rejected, state => {
      state.isAddingItem = false
    })

    builder.addCase(addItem.pending, state => {
      state.isAddingItem = true
    })

    builder.addCase(addItem.fulfilled, state => {
      state.isAddingItem = false
    })

    builder.addCase(addRXCItem.rejected, state => {
      state.isAddingItem = false
    })

    builder.addCase(addRXCItem.pending, state => {
      state.isAddingItem = true
    })

    builder.addCase(addRXCItem.fulfilled, state => {
      state.isAddingItem = false
    })

    builder.addCase(fetchShipInfo.fulfilled, (state, action) => {
      state.isFetchingShippingInfo = false
      state.shipInfos = getShipModeWithLongestLeadTime(action.payload?.usableShippingMode as UsableShippingMode[])
    })

    builder.addCase(fetchShipInfo.rejected, state => {
      state.isFetchingShippingInfo = false
      state.shipInfos = undefined
    })

    builder.addCase(fetchShipInfo.pending, state => {
      state.isFetchingShippingInfo = true
    })

    builder.addCase(fetchPayMethods.fulfilled, (state, { payload }) => {
      if (payload.usablePaymentInformation) {
        const cardsList: any[] = []
        for (const payment of payload.usablePaymentInformation) {
          cardsList.push(payment)
        }

        if (cardsList.length > 0) {
          state.payMethods = cardsList
        }
      }
    })

    builder.addCase(fetchPaypalExpress.pending, state => {
      state.paypalExpress = {
        ...state.paypalExpress,
        isSelected: true,
        loading: true,
        error: false,
      }
    })

    builder.addCase(fetchPaypalExpress.rejected, state => {
      state.paypalExpress = {
        ...state.paypalExpress,
        isSelected: false,
        loading: false,
        error: true,
      }
    })

    builder.addCase(fetchPaypalExpress.fulfilled, (state, { payload }) => {
      if (payload) {
        state.paypalExpress = {
          ...state.paypalExpress,
          loading: false,
          error: false,
          redirecturl: payload.redirecturl,
        }
      }
    })

    builder.addCase(finalizeOrder.pending, (state: IOrderSliceState) => {
      state.isFinalizing = true
    })

    builder.addCase(finalizeOrder.fulfilled, (state: IOrderSliceState, { payload }) => {
      const info = payload.orderFinalizedInfo
      const ppExpress = payload.orderFinalizationBody?.ppExpress
      state.isFinalizing = false
      state.orderComplete = [ORDER_STATUS.Created, ORDER_STATUS.PendingPrescription].includes(info?.orderStatus)
      state.orderItems = [...info.orderItem]
      state.paypalExpress.isSelected = ppExpress
    })

    builder.addCase(finalizeOrder.rejected, (state: IOrderSliceState) => {
      state.isFinalizing = false
    })
    builder.addCase(SET_SUBSCRIPTION_ORDER_COMPLETE_ACTION, (state: IOrderSliceState, { payload }) => {
      state.orderComplete = payload
    })

    builder.addCase(fetchCart.rejected, (state, action) => {
      //! SORRY TEAM THIS CAST TO ANY WAS NECESSARY BECAUSE TYPES ARE JUST BROKEN
      //! HOPE TO FIX THEM ALL ASAP ☮️
      const error = action.error as any
      if (error?.response?.status && error.response.status === NOT_FOUND) {
        state.cart = undefined
        state.numItems = 0
        state.orderItems = []
      }
      state.isCheckoutDisabled = true
      state.isFetching = false
    })

    builder.addCase(fetchCart.pending, state => {
      state.isFetching = true
    })
    

    builder
      .addMatcher(
        isAnyOf(
          addItem.fulfilled,
          addFrame.fulfilled,
          addLens.fulfilled,
          addRXCItem.fulfilled,
          addContactLenses.fulfilled,
          addItem.rejected,
          addFrame.rejected,
          addLens.rejected,
          addContactLenses.rejected,
          addLens.rejected,
          addRXCItem.rejected
        ),
        state => {
          state.isAddingItem = false
        }
      )
      .addMatcher(isAnyOf(addItem.pending, addFrame.pending, addLens.pending, addRXCItem.pending), state => {
        state.isAddingItem = true
      })
      .addMatcher(orderApi.endpoints.getShippingInfo.matchFulfilled, (state, { payload }: PayloadAction<any>) => {
        state.isFetchingShippingInfo = false
        state.refetchShipInfos = false
        state.shipInfos = getShipModeWithLongestLeadTime(payload?.usableShippingMode as UsableShippingMode[])
      })
      .addMatcher(
        orderApi.endpoints.getShippingFsaLeadTimeMap.matchFulfilled,
        (state, { payload }: PayloadAction<any>) => {
          state.shipFsaLeadTime = payload
        }
      )
      .addMatcher(isAnyOf(orderApi.endpoints.finalizeOrderWithCybersource.matchRejected), state => {
        state.isFinalizing = false
        state.isFinalizationFailed = true
      })
      .addMatcher(orderApi.endpoints.getCart.matchPending, (state, payload) => {
        const { meta } = payload
        const originalArgs = meta?.arg?.originalArgs
        state.isFetchingShippingInfo = true
        state.isFetching = originalArgs?.refetch ? !originalArgs?.refetch : true
      })
      .addMatcher(orderApi.endpoints.getCart.matchRejected, state => {
        state.isFetchingShippingInfo = false
        state.isFetching = false
      })
      .addMatcher(
        orderApi.endpoints.getCart.matchFulfilled,
        (
          state,
          {
            payload,
          }: PayloadAction<{
            cart: Cart
            catentries?: IOrderSliceState['catentries']
            shippingInfo: IOrderSliceState['shipInfos']
            checkInventory: boolean
            fetchShippingInfo: boolean
            filterPrescriptionItemType?: 'cl' | 'rx'
            filterPrescriptionNeededItems?: boolean
          }>
        ) => {
          if (payload) {
            const {
              catentries,
              checkInventory,
              cart,
              fetchShippingInfo,
              filterPrescriptionItemType,
              filterPrescriptionNeededItems,
            } = payload
            const orderItems = cart?.orderItem

            const orderItemsAttachments = getOrderItemsAttachments(orderItems, catentries)

            state.catentries = catentries
            state.cart = cart || null
            state.refetchShipInfos = fetchShippingInfo
            let count = 0
            if (orderItems) {
              count = orderItems.reduce((c: number, item: any) => +item.quantity + c, 0)
            }
            state.numItems = count
            let newOrderItems: any[] = []
            let disableRecurringOrder = false
            let disableCheckout = false
            if (orderItems && orderItems.length > 0) {
              newOrderItems = []
              orderItems.forEach((item: OrderItem) => {
                if (checkInventory) {
                  if (
                    item.orderItemInventoryStatus !== INVENTORY_STATUS.available &&
                    item.orderItemInventoryStatus !== INVENTORY_STATUS.allocated
                  ) {
                    disableCheckout = true
                  }
                }
                const obj: OrderItem | OrderItemWithRoxProps = {
                  ...item,
                }

                const catentryId = item.productId
                if (catentries != null) {
                  const catentry = catentries[catentryId]
                  if (catentry !== undefined) {
                    if (catentry.name !== undefined) {
                      obj['name'] = catentry.name
                    }
                    if (catentry.thumbnail !== undefined) {
                      obj['thumbnail'] = catentry.thumbnail
                    }
                    if (catentry.attributes !== undefined) {
                      obj['attributes'] = catentry.attributes
                    }
                    if (catentry.seo !== undefined) {
                      obj['seo'] = catentry.seo
                    }
                    if (catentry.disallowRecurringOrder !== undefined) {
                      obj['disallowRecurringOrder'] = catentry.disallowRecurringOrder
                      if (catentry.disallowRecurringOrder === '1') {
                        disableRecurringOrder = true
                      }
                    }
                    if (catentry.parentCatalogGroupID !== undefined) {
                      obj['parentCatalogGroupID'] = catentry.parentCatalogGroupID
                    }

                    if (catentry.x_offerpriceRx !== undefined) {
                      obj['x_offerpriceRx'] = catentry.x_offerpriceRx
                    }
                    if (catentry.x_price !== undefined) {
                      obj['x_price'] = catentry.x_price
                    }
                    if (catentry.x_priceBadge !== undefined) {
                      obj['x_priceBadge'] = catentry.x_priceBadge
                    }
                    obj.attachments = orderItemsAttachments[obj.productId]
                  }
                }
                obj.prescriptionUploaded = orderHasPrescriptionUploaded(item) || false

                newOrderItems.push(obj)
              })

              state.isCheckoutDisabled = disableCheckout
              state.isRecurringOrderDisabled = disableRecurringOrder
            }
            state.orderItems = newOrderItems
            state.parsedOrderItems = getOrderItemsMap(state.orderItems)
            if (state.cart) {
              state.cart.productCount = getCartProductCount(state.orderItems)
            }
            state.orderComplete = !!cart ? cart.orderStatus === ORDER_STATUS.Created : true
            state.isRXProductsLimitExceeded =
              newOrderItems.filter(item => isRxProduct(item?.orderItemExtendAttribute)).length >
              RX_PRODUCTS_IN_CART_LIMIT

            const updatedPrescriptionMacroGroups = getPrescriptionItemsMap(
              newOrderItems,
              filterPrescriptionNeededItems,
              filterPrescriptionItemType
            )
            if (updatedPrescriptionMacroGroups) {
              state.usePrescription.prescriptionMacroGroups = updatedPrescriptionMacroGroups
            }
          }
          if (state.isRecurringOrderDisabled && state.cart && state.cart.orderId) {
            if (localStorageUtil.get(ACCOUNT + HYPHEN + ORDER_ID + HYPHEN + state.cart.orderId)) {
              const recurringOrderInfo: any[] = [false, '0', null]
              localStorageUtil.set(ACCOUNT + HYPHEN + ORDER_ID + HYPHEN + state.cart.orderId, recurringOrderInfo)
            }
          }
          state.isFetching = false
          state.isFetchingShippingInfo = false
        }
      )
      .addMatcher(orderApi.endpoints.findOrderdById.matchPending, state => {
        state.isFetchingOrderDetails = true
      })
      .addMatcher(
        orderApi.endpoints.findOrderdById.matchFulfilled,
        (
          state,
          {
            payload,
          }: PayloadAction<{
            orderData: IOrderDetails
            catentries?: IOrderSliceState['catentries']
          }>
        ) => {
          if (payload) {
            const { orderData, catentries } = payload

            const { orderStatus } = orderData

            const updatedOrderItems = orderData.orderItem?.map(oi => {
              const orderItem = { ...oi }
              const p = orderData?.x_data?.productDetails?.find(({ id }) => orderItem?.productId === id)
              const rxServices = orderItem?.roxableServices
              const rxServicesDetails = orderData?.x_data?.productDetails.filter(({ id }) =>
                rxServices?.some(s => s.productId === id)
              )
              const catentryId = orderItem.productId
              if (catentries != null) {
                const catentry = catentries[catentryId]
                if (catentry !== undefined) {
                  if (catentry.attributes !== undefined) {
                    orderItem['attributes'] = catentry.attributes
                  }
                }
              }

              const newOrderItem: OrderItem = {
                ...orderItem,
                attachments: p?.attachments || [],
                attributes:
                  p?.attributes ||
                  state.orderItems.find(item => item.orderItemId === orderItem.orderItemId)?.attributes ||
                  [],
                roxableServices: rxServicesDetails,
                orderItemPrice: orderItem?.orderItemPrice || '',
                currency: orderItem?.currency || '',
                productId: orderItem?.productId || '',
                x_offerpriceRx: p?.x_offerpriceRx,
                prescriptionUploaded: orderHasPrescriptionUploaded(orderItem) || false,
              }
              return newOrderItem
            })

            state.orderItems = updatedOrderItems

            const orderPaymentMethodId = orderData?.paymentInstruction?.find(pi => !!pi)?.payMethodId

            state.orderDetails = {
              ...orderData,
              orderItem: updatedOrderItems,
              orderComplete: isOrderComplete(orderStatus, orderPaymentMethodId),
              parsedOrderItems: getOrderItemsMap(updatedOrderItems),
              prescriptionPeriodExpired: orderHasPrescriptionPeriodExcedeed(orderData, 10),
            }
            state.orderComplete = isOrderComplete(orderStatus, orderPaymentMethodId)

            state.isFetchingOrderDetails = false

            if (catentries) {
              state.catentries = catentries
            }
          }
        }
      )
      .addMatcher(orderApi.endpoints.findOrderdById?.matchRejected, state => {
        state.isFetchingOrderDetails = false
      })
      .addMatcher(
        orderApi.endpoints.getShippingPrice?.matchFulfilled,
        (
          state,
          {
            payload,
          }: PayloadAction<{
            shipList?: ShippingChargesWithoutPromotions[]
          }>
        ) => {
          if (payload?.shipList && payload.shipList.length) {
            state.cartShippingCharges.shippingCharges = payload?.shipList
          }
        }
      )
      .addMatcher(orderApi.endpoints.getShippingPrice?.matchRejected, (state, { payload }) => {
        state.cartShippingCharges.error = payload
      })
      .addMatcher(orderApi.endpoints.getOneClickReorder?.matchFulfilled, (state, { payload }) => {
        state.reorderInfo = payload
      })
  },
})

export const {
  updateCart,
  resetCart,
  toggleCartOrderComplete,
  toggleShippingAsBilling,
  togglePaypalExpress,
  setBillingFormValidStatus,
  setPaymentMethod,
  setIsPromoCouponApplied,
  setShippingFormValidStatus,
  toggleSameUserItemPrescription,
  //setUserPrescriptionsItems,
  setSelectedItemIndex,
  setSelectedPrescriptionGroupIndex,
  setUserPrescriptionsGroups,
  setUserPrescriptionFormData,
  setUserPrescriptionItemSkipped,
  setUserPrescriptionItemCompleted,
  setUserPrescriptionGroupActive,
  setCreditCardNumber,
  resetCreditCardNumber,
  setSelectedShipModeCode,
  setReorderId,
  resetReorderId,
  setReorderInfo,
  resetReorderInfo,
  setApplePayFlow,
} = orderSlice.actions

export default orderSlice.reducer
