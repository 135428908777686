import { OrderItemsAttachments } from '../types/order'
import { customPalette } from '../themes/color-palette'
import { IOrderSliceState } from '../features/order/IOrderSliceState'
import { TFunction } from 'i18next'
import theme from '../themes'

export const INVENTORY_STATUS = {
  available: 'Available',
  allocated: 'Allocated',
  backordered: 'Backordered',
  unallocated: 'Unallocated',
}

export const RECURRING_ORDER_OPTIONS = [
  {
    key: 'EVERYDAY',
    value: '0',
    fulfillmentInterval: '1',
    fulfillmentIntervalUOM: 'DAY',
    translationKey: 'CommerceEnvironment.recurringOrderFrequency.Everyday',
  },
  {
    key: 'EVERYWEEK',
    value: '1',
    fulfillmentInterval: '1',
    fulfillmentIntervalUOM: 'WEE',
    translationKey: 'CommerceEnvironment.recurringOrderFrequency.EveryWeek',
  },
  {
    key: 'EVERYTWOWEEKS',
    value: '2',
    fulfillmentInterval: '2',
    fulfillmentIntervalUOM: 'WEE',
    translationKey: 'CommerceEnvironment.recurringOrderFrequency.EveryTwoWeeks',
  },
  {
    key: 'EVERYTHREEWEEKS',
    value: '3',
    fulfillmentInterval: '3',
    fulfillmentIntervalUOM: 'WEE',
    translationKey: 'CommerceEnvironment.recurringOrderFrequency.EveryThreeWeeks',
  },
  {
    key: 'EVERYFOURWEEKS',
    value: '4',
    fulfillmentInterval: '4',
    fulfillmentIntervalUOM: 'WEE',
    translationKey: 'CommerceEnvironment.recurringOrderFrequency.EveryFourWeeks',
  },
]

export const SHIPMODE = {
  shipModeCode: {
    PickUp: 'PickupInStore',
  },
}

export const EXPIRY = {
  MONTHS: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
  YEARS: ['2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030'],
}

export const PO_NUMBER = 'poNumber'
export const TAXES_ZIP_CODE = 'TAXES_ZIP_CODE'
export const ESTIMATED_TAXES = 'ESTIMATED_TAXES'

export const IS_RETURN_ENABLED = true

export const ORDER_STATUS = {
  Submitted: 'I',
  Created: 'M',
  Late: 'L',
  Error: 'H',
  Settled: 'D',
  Confirmed: 'R',
  Cancelled: 'X',
  Delivered: '1',
  PendingPrescription: 'E',
  ReturnAssociated: 'K',
  Hold: 'U',
  PendingBankTransfer: 'Q',
  NotDelivered: '0',
}

export const ORDER_RETURN_STATUS = {
  ReturnCreated: 'APP',
  ReturnReceived: 'CRE',
  ReturnComplete: 'CLO',
  ReturnCancelled: 'CAN',
  ReturnPending: 'PND',
  ReturnDelivered: 'SNT',
}

export const ORDER_ITEM_STATUS = {
  Created_SAPSent: 'G',
  Created_Error: 'F',
  Created_M: 'M',
  Created_F: 'F',
  PendingPrescription_V: 'V',
  PendingPrescription_ToVerified: 'W',
  Cancelled_X: 'X',
  Cancelled_PrescriptionRejected: 'N',
  InProgress_Confirmed: 'R',
  InProgress_Late: 'L',
  Shipped_InTransit: 'S',
  Shipped_Billed: 'O',
  Shipped_Settled: 'D',
  Shipped_FailedStatement: 'Z',
  Delivered: '1',
}

export const ORDER_STATUS_ISSUES = [ORDER_STATUS.Cancelled, ORDER_STATUS.Hold, ORDER_STATUS.NotDelivered]

export const PENDING_PRESCRIPTION_STATUSES = [ORDER_ITEM_STATUS.PendingPrescription_V, ORDER_STATUS.PendingPrescription]

export const SHIPPED_STATUSES = [
  ORDER_ITEM_STATUS.Shipped_Billed,
  ORDER_ITEM_STATUS.Shipped_FailedStatement,
  ORDER_ITEM_STATUS.Shipped_Settled,
  ORDER_ITEM_STATUS.Shipped_InTransit,
]

export const DELIVERED_STATUSES = [ORDER_ITEM_STATUS.Delivered, ORDER_RETURN_STATUS.ReturnDelivered]

export const ORDER_STATUSES = [ORDER_ITEM_STATUS.Delivered, ORDER_RETURN_STATUS.ReturnDelivered]

const RMA_ORDER_STATUS_COLOR = theme.palette.secondary.greyLightmode

export const ORDER_EXTEND_ATTRIBUTE_NAMES = {
  INVOICE_PROCESSED: 'INVOICE_PROCESSED',
  INVOICE_URLS: 'xinvoiceUrls',
  IS_MIX_ORDER: 'IS_MIX_ORDER', // 'true' | 'false'
  IS_ROX_ORDER: 'IS_ROX_ORDER', // 'true' | 'false'
  IS_CONTACT_LENS: 'isContactLens', // 'true' | 'false'
  HAS_CONTACT_LENS: 'hasContactLens', // 'true' | 'false'
  LANG_ID: 'LanguageId',
  RMA_ITEMS_COMPLETED: 'numberOfRmaItemsCompleted',
  RMA_ITEMS_IN_PROGRESS: 'numberOfRmaItemsInProgress',
  RMA_STATUS: 'RMAStatus',
  TAX_SETTLEMENT: 'TaxSettlement',
  TAX_SYSTEM: 'TaxSystem',
  TRACK_NUMBER: 'track_number',
  X_CONTACT_LENS: 'x_contactLens',
  X_CLAIM_APPLIED: 'CLAIM_APPLIED',
  X_CLAIM_TOTAL_AMOUNT: 'totalInsuranceAdjustment',
  ORDER_REMINDER_DATE: 'reminderDate',
  PARENT_ORDER_ID: 'parentOrderId',
} as const

export const ORDER_ITEM_EXTEND_ATTRIBUTE_NAMES = {
  RMA_STATUS: 'RMA',
} as const

export const STANDARD_ONLY_FSA_CODES = [
  'A0P',
  'G0G',
  'J0M',
  'P0L',
  'P0T',
  'P0V',
  'R0B',
  'R0C',
  'T0H',
  'V0J',
  'V0N',
  'V0P',
  'V0R',
  'V0V',
  'X0A',
  'X0C',
  'X0E',
  'Y0B',
]

export const RETURN_REASONS_CODE_PREFIX = 'RET'
export const RETURN_ITEM_TYPE = 'REF'

export interface TaxValues {
  provincial: number
  federal: number
}

const orderItemExtendAttributeNameGetter = (orderId: string, name: string) => `${orderId}:${name}`

export const ORDER_ITEM_EXTEND_ATTRIBUTE_NAME_GETTERS = {
  TRACK_NUMBER: (orderId: string) => orderItemExtendAttributeNameGetter(orderId, 'TrackNumber'),
  FULFILLMENT_STATUS: (orderId: string) => orderItemExtendAttributeNameGetter(orderId, 'FulfillmentStatus'),
  IS_CONTACT_LENS: (orderId: string) => orderItemExtendAttributeNameGetter(orderId, 'isContactLens'),
  X_CONTACT_LENS: (orderId: string) => orderItemExtendAttributeNameGetter(orderId, 'x_contactLens'),
}

export const NO_CCARD_INFO_NEEDED_PAYMENTS: string[] = ['paypal', 'applepay', 'cod', 'CyberSourceKlarna']

export const CCARD_INFO_PAYMENTS: string[] = ['Visa', 'American Express', 'Master Card']

export const getOrderItemsAttachments = (
  orderItems: IOrderSliceState['orderItems'],
  catentries: IOrderSliceState['catentries']
) => {
  if (!Array.isArray(orderItems) || !catentries) {
    return {}
  }

  const orderItemsImages = {} as OrderItemsAttachments

  for (const orderItem of orderItems) {
    const { productId } = orderItem
    const attachments = catentries[productId]?.attachments

    orderItemsImages[productId] = attachments || []
  }

  return orderItemsImages
}

export const buildOrderHistoryHeaders = (t: TFunction): string[] => [
  t('Order.OrderNumber'),
  t('Order.OrderDate'),
  t('Order.Status'),
  t('Order.TrackingNumber'),
  t('Order.TotalPrice'),
  t('Order.Actions'),
]

const [green, orange, red] = [
  customPalette.clearly.light1.green,
  customPalette.clearly.light1.orange,
  customPalette.clearly.light1.red,
]

export const renderOrderItemStatusColor = (
  orderItemStatus: (typeof ORDER_ITEM_STATUS)[keyof typeof ORDER_ITEM_STATUS]
): string => {
  switch (orderItemStatus) {
    case '1':
    case 'C':
    case 'D':
    case 'R':
    case 'M':
    case 'F':
    case 'H':
    case 'G':
    case 'I':
    case 'O':
    case 'S':
    case 'Z':
      return green
    case 'X':
    case 'N':
      return red
    case 'V':
    case 'W':
      return orange
    default:
      return '#000000'
  }
}

export const renderOrderStatusColor = (orderStatus: (typeof ORDER_STATUS)[keyof typeof ORDER_STATUS]): string => {
  switch (orderStatus) {
    case '1':
    case 'C':
    case 'D':
    case 'F':
    case 'G':
    case 'H':
    case 'I':
    case 'L':
    case 'M':
    case 'O':
    case 'R':
    case 'R_order':
    case 'S':
    case 'SNT':
    case 'Shipped':
    case '2':
    case '5':
      return green
    case 'N':
    case '0':
    case 'X':
      return red
    case 'E':
    case 'A':
    case 'V':
    case '3':
    case '4':
      return orange
    default:
      return '#000000'
  }
}

export const MAX_PURCHASABLE_QUANTITY_CL_ACCESSORY = 9

export const PRODUCT_COUNT_IN_CART_LIMIT = 10 // TODO move this in a configuration

export const RX_PRODUCTS_IN_CART_LIMIT = 999999 // TODO move this in a configuration

export const ADJUSTMENT_DISPLAY_LEVEL = {
  ORDER: 'Order',
  ORDER_ITEM: 'OrderItem',
}
