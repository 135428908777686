import { get } from 'lodash-es'
import CurrencyService from '@services/CurrencyService'
import Log from '@services/Log'
import { Cart, Order } from '@typesApp/order'
import { EMPTY_OBJECT_STRING_REPRESENTATION } from '@constants/common'

export function getCartTotalsSummary(cart: Cart, _isTaxAvailable?: boolean) {
  let subtotal: number | null = null
  let tax: number | null = null
  let shippingName: string | null = null
  let shipping: number | null = null
  let shippingTax: number | null = null
  let totalDiscounts: number | null = null
  let grandTotal: number | null = null
  let currency: string | null = null
  if (cart) {
    try {
      subtotal = cart.totalProductPrice ? parseFloat(cart.totalProductPrice) : null
      tax = parseFloat(cart.totalSalesTax) || null
      shippingName = get(cart, 'orderItem[0].shipModeDescription')
      shipping = cart.totalShippingCharge ? parseFloat(cart.totalShippingCharge) : null
      shippingTax = cart.totalShippingTax ? parseFloat(cart.totalShippingTax) : null
      totalDiscounts = cart.totalAdjustment ? parseFloat(cart.totalAdjustment) : null
      grandTotal = cart.grandTotal ? parseFloat(cart.grandTotal) : null
      currency = CurrencyService.getSymbolByName(cart.grandTotalCurrency) || null
    } catch (e) {
      Log.error('Could not parse order total summary')
    }
  }
  return {
    subtotal,
    tax,
    shippingName,
    shipping,
    shippingTax,
    totalDiscounts,
    grandTotal,
    currency,
  }
}
export const formatValuetoJSON = (value: string) => {
  if (!value || value === EMPTY_OBJECT_STRING_REPRESENTATION) return []
  let newValue = value
  if (value.substring(0, 2) === '{[') newValue = value.substring(1, value.length - 1)
  return JSON.parse(newValue)
}

export const getOrderDiscountAmount = (order: Order): number => {
  return (order?.adjustment ?? [])
    .filter(el => el.usage === 'Discount' && el.displayLevel === 'Order')
    .reduce((accumulator, adjustment) => accumulator + parseFloat(adjustment.amount), 0)
}
