import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from '../../services/AxiosBaseQuery'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react'
import { RootReducerState } from '@redux/rootReducer'
import { RxPrescriptionDetails } from '@typesApp/prescription'
import { PrescriptionDetailsPayload, PrescriptionFilePayload, PrescriptionPreviewPayload } from '@typesApp/checkout'
import { isValidRxValue } from '@components/PrescriptionLenses/RxUtils'

export interface IPrescription {
  idXprescription?: string
  nickName?: string
  type?: string
  rightSphere: string
  rightAdd: string
  rightAxis: string
  rightCylinder?: string
  rightCyl?: string
  leftSphere: string
  leftAdd: string
  leftAxis: string
  leftCylinder?: string
  leftCyl?: string
  lPupDistance: string
  rPupDistance: string
  pupillaryDistance: string
  prescriptionUrl?: string
  dateCreated?: string
  markIfDeleted?: string
  issue?: string
  doctor?: string
}
export interface IUserPrescription {
  code: string
  userId: number
  errorMessage?: string
  Prescription: IPrescription[]
  recordCount: number
}
export interface IUserPrescriptionArgs {
  userId: number
  storeId: string | undefined
}

export const prescriptionApi = createApi({
  reducerPath: 'prescriptionApi',
  baseQuery: axiosBaseQuery({
    baseUrl: '/store/{storeId}/prescription/myaccount',
  }),

  endpoints: build => ({
    addOrUpdatePrescription: build.mutation({
      query: payload => ({
        url: '/prescriptionDetails',
        method: payload.prescription?.id ? 'PUT' : 'POST',
        body: payload,
        pathParams: { storeId: payload?.storeId },
      }),
    }),
    uploadRxFile: build.mutation({
      query: payload => ({
        url: '/fileUpload',
        method: 'POST',
        body: payload,
      }),
    }),
    deletePrescription: build.mutation({
      query: id => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
    }),
    deleteRxFile: build.mutation({
      query: id => ({
        url: `/${id}}`,
        method: 'DELETE',
      }),
    }),
    getRxListForRox: build.mutation<IUserPrescription, any>({
      queryFn: async (args, queryApi, _extraOptions, fetchWithBQ) => {
        const state = queryApi.getState() as RootReducerState
        const storeId = state.site.currentSite?.storeID
        const params: IUserPrescriptionArgs = {
          ...args,
          storeId: storeId,
        }
        const result = await fetchWithBQ({
          url: '/@self',
          method: 'get',
          queryParams: params || {},
        })

        return result?.data
          ? { data: filterPrescriptions(result.data as IUserPrescription) }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
    getPrescriptions: build.query<IUserPrescription, any>({
      queryFn: async (args, queryApi, _extraOptions, fetchWithBQ) => {
        const state = queryApi.getState() as RootReducerState
        const storeId = state.site.currentSite?.storeID
        const params: IUserPrescriptionArgs = {
          ...args,
          storeId: storeId,
        }
        const result = await fetchWithBQ({
          url: '/@self',
          method: 'get',
          queryParams: params || {},
          pathParams: { storeId },
        })

        return result?.data
          ? { data: filterPrescriptions(result.data as IUserPrescription) }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
    uploadPrescriptionFile: build.mutation<any, PrescriptionFilePayload>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId, formData } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/prescription/storeRXFile`,
          method: 'POST',
          headers: { 'Content-Type': 'multipart/form-data' },
          body: formData || {},
          pathParams: { storeId },
        })

        return result?.data ? { data: result.data } : { error: result.error }
      },
    }),
    uploadRxPrescriptionFile: build.mutation<any, PrescriptionFilePayload>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId, formData } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/prescription/uploadFile`,
          method: 'POST',
          headers: { 'Content-Type': 'multipart/form-data' },
          body: formData || {},
        })

        return result?.data ? { data: result.data } : { error: result.error }
      },
    }),
    uploadRxMultiplePrescriptionFile: build.mutation<any, PrescriptionFilePayload>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId, formData } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/prescription/uploadFile/multiple`,
          method: 'POST',
          headers: { 'Content-Type': 'multipart/form-data' },
          body: formData || {},
        })

        return result?.data ? { data: result.data } : { error: result.error }
      },
    }),
    uploadPrescriptionDetails: build.mutation<any, PrescriptionDetailsPayload>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/contactLens/prescriptionDetails`,
          method: 'POST',
          body:
            {
              ...args.details,
            } || {},
        })

        return result?.data ? { data: result.data } : { error: result.error }
      },
    }),
    uploadRxPrescriptionDetails: build.mutation<any, PrescriptionDetailsPayload>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/prescription/prescriptionDetails`,
          method: 'PUT',
          body:
            {
              ...args.details,
            } || {},
        })

        return result?.data ? { data: result.data } : { error: result.error }
      },
    }),
    uploadRxMultiplePrescriptionDetails: build.mutation<any, PrescriptionDetailsPayload>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/prescription/prescriptionDetails/multiple`,
          method: 'POST',
          body:
            {
              ...args.details,
            } || {},
        })

        return result?.data ? { data: result.data } : { error: result.error }
      },
    }),
    getPrescriptionFilePreview: build.query<any, PrescriptionPreviewPayload>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId, rxFileStorageId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/prescription/rxFileStorageId/${rxFileStorageId}`,
          method: 'GET',
          extraParams: {
            responseType: 'arraybuffer',
          },
          body:
            {
              ...args,
            } || {},
          pathParams: { storeId },
        })
        return result?.data
          ? {
              data: {
                content: result.data,
                headers: result.headers,
              },
            }
          : { error: result.error }
      },
    }),
    getPrescriptionDetails: build.query<
      RxPrescriptionDetails,
      { storeId: string; orderId: string; orderItemId: string }
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId, orderId, orderItemId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/prescription/orderId/${orderId}/orderItemId/${orderItemId}`,
          method: 'GET',
          body:
            {
              ...args,
            } || {},
        })
        return result?.data
          ? {
              data: result.data.results as RxPrescriptionDetails,
            }
          : { error: result.error }
      },
    }),
    getUploadedPrescriptionFileData: build.query<any, { storeId: string; orderId: string; orderItemId: string }>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId, orderId, orderItemId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/contactLens/rxUploadedFile/orderId/${orderId}/orderItemId/${orderItemId}`,
          method: 'GET',
          extraParams: {
            responseType: 'arraybuffer',
          },
          body:
            {
              ...args,
            } || {},
        })
        return result?.data
          ? {
              data: {
                content: result.data,
                headers: result.headers,
              },
            }
          : { error: result.error }
      },
    }),
  }),
})

/**
 * @returns the valid prescriptions, i.e. those with at least one non 'null' value.
 * Ideally B/E should fix the invalid data, this workaround will prevent invalid prescriptions from showing.
 */
const filterPrescriptions = (result: IUserPrescription): IUserPrescription => {
  return {
    ...result,
    Prescription: result.Prescription?.filter(p => isValidPrescription(p)) || [],
  }
}

const isValidPrescription = (prescription: IPrescription): boolean => {
  return (
    isValidRxValue(prescription.rightAdd) ||
    isValidRxValue(prescription.rightAxis) ||
    isValidRxValue(prescription.rightCyl) ||
    isValidRxValue(prescription.rightSphere) ||
    isValidRxValue(prescription.leftAdd) ||
    isValidRxValue(prescription.leftAxis) ||
    isValidRxValue(prescription.leftCyl) ||
    isValidRxValue(prescription.leftSphere)
  )
}

export const {
  useAddOrUpdatePrescriptionMutation,
  useDeletePrescriptionMutation,
  useDeleteRxFileMutation,
  useGetPrescriptionsQuery,
  useLazyGetPrescriptionsQuery,
  useGetRxListForRoxMutation,
  useUploadRxFileMutation,
} = prescriptionApi
