import last from 'lodash/last'
import orderBy from 'lodash/orderBy'
import { isRxCart, parseRxOrderItems } from '@utils/rx'

//Redux
import { RootReducerState } from '@redux/rootReducer'
import { IOrderDetails, IReorderInfo, ParsedOrderItemsMapByItemType } from '@typesApp/order'
import { PrescriptionFormData } from '@typesApp/prescription'
import { PrescriptionMacroGroup } from '@typesApp/checkout'
import { ORDER_ITEM_SUBSCRIPTION_ATTRIBUTE } from '@constants/common'
import { MDT_DISCOUNT_AMOUNT, MDT_DISCOUNT_SEGMENT } from '@components/PrescriptionLenses/RxUtils'

export const orderSelector = (state: RootReducerState) => state.order
export const cartSelector = (state: RootReducerState) => state.order.cart!
export const cartProductCountSelector = (state: RootReducerState): number => state.order.cart?.productCount ?? 0
export const numItemsSelector = (state: RootReducerState) => state.order.numItems
export const orderItemsSelector = (state: RootReducerState) => state.order.orderItems
export const catentriesSelector = (state: RootReducerState) => state.order.catentries
export const isCheckoutDisabledSelector = (state: RootReducerState) => state.order.isCheckoutDisabled
export const isRXOrderSelector = ({ order }: RootReducerState) => order.cart?.IS_RX === 'true'
export const shipInfosSelector = (state: RootReducerState) => state.order.shipInfos
export const refetchShipInfosSelector = (state: RootReducerState) => state.order.refetchShipInfos
export const shipFsaLeadTimeSelector = (state: RootReducerState) => state.order.shipFsaLeadTime
export const payMethodsSelector = (state: RootReducerState) => state.order.payMethods
export const isRecurringOrderDisabledSelector = (state: RootReducerState) => state.order.isRecurringOrderDisabled
export const isFetchingSelector = (state: RootReducerState) => state.order.isFetching
export const isAddingItemSelector = (state: RootReducerState) => state.order.isAddingItem
export const addedToCartProductSelector = (state: RootReducerState) => state.order.orderItems || []
export const creditCardNumberSelector = (state: RootReducerState) => state.order?.creditCardNumber || null
export const shippingPricesSelector = (state: RootReducerState) => state.order.cartShippingCharges
export const lastProductAddedToCartSelector = (state: RootReducerState) => {
  const orderItems = isRxCart(state.order.cart?.orderExtendAttribute || [])
    ? parseRxOrderItems(state.order.orderItems)
    : state.order.orderItems
  const ordered = orderBy(orderItems, ['lastUpdateDate'], ['asc'])
  return last(ordered)
}
export const subscriptionItemsCountSelector = (state: RootReducerState) => {
  return state.order.orderItems.filter(
    orderItem =>
      !!orderItem.orderItemExtendAttribute?.find(a => a.attributeName === ORDER_ITEM_SUBSCRIPTION_ATTRIBUTE)
        ?.attributeName
  ).length
}
export const paypalExpressSelector = (state: RootReducerState) => state.order.paypalExpress
export const isFetchingShippingInfoSelector = (state: RootReducerState) => state.order.isFetchingShippingInfo
export const isFetchingOrderDEtailsSelector = (state: RootReducerState) => state.order.isFetchingOrderDetails
export const isShippingAsBillingSelector = (state: RootReducerState) => state.order.isShippingUsedAsBilling
export const isPromoCouponAppliedSelector = (state: RootReducerState) => state.order.isPromoCouponApplied
export const orderCompleteSelector = (state: RootReducerState) => state.order.orderComplete
export const orderFinalizingSelector = (state: RootReducerState) => state.order.isFinalizing
export const orderFinalizingFailSelector = (state: RootReducerState) => state.order.isFinalizationFailed
export const selectedPayMethodsSelector = (state: RootReducerState) => state.order.selectedPayMethodInfoList
export const shippingFormStatusSelector = (state: RootReducerState) => state.order.shippingFormStatus
export const billingFormStatusSelector = (state: RootReducerState) => state.order.billingFormStatus
export const totalOrderDiscountSelector = (state: RootReducerState) => {
  const { adjustment, orderItem } = state?.order?.cart ?? {}
  const totalAdjustmentDiscount = (adjustment ?? [])?.reduce((total, adj) => {
    total += Number(adj.amount)
    return total
  }, 0)
  /* Read Segment MDT discount from OrderItemExtendAttribute */
  const totalOrderDiscount = (orderItem ?? [])?.reduce((total, orderItem) => {
    const discountAmount = parseFloat(
      orderItem?.orderItemExtendAttribute?.find(attr => attr?.attributeName === MDT_DISCOUNT_AMOUNT)?.attributeValue ??
        '0'
    )
    const quantity = parseFloat(orderItem?.quantity ?? 1)
    total += discountAmount * quantity
    return total
  }, totalAdjustmentDiscount)
  return String(totalOrderDiscount)
}
export const orderDiscountNamesSelector = (state: RootReducerState) => {
  const { adjustment, orderItem } = state?.order?.cart ?? {}
  const adjustmentNames = (adjustment ?? [])?.reduce((discounts, adj) => {
    const code = adj.code?.replace(' ', '')
    discounts.push(code)
    return discounts
  }, [] as string[])
  /* Read Segment Name from OrderItemExtendAttribute */
  const orderDiscountNames = (orderItem ?? [])?.reduce((discounts, orderItem) => {
    const segmentName =
      orderItem?.orderItemExtendAttribute?.find(attr => attr?.attributeName === MDT_DISCOUNT_SEGMENT)?.attributeValue ??
      ''
    if (segmentName !== '' && segmentName !== 'Guest' && !discounts.includes(segmentName)) {
      discounts.push(segmentName)
    }
    return discounts
  }, adjustmentNames as string[])
  return orderDiscountNames?.join(',')
}

export const appliedPromotionCodesSelector = (state: RootReducerState) => {
  return state.order.cart?.promotionCode?.reduce((codes, promotionCode, i) => {
    if (i !== 0) {
      codes += `,${promotionCode.code}`
    } else {
      codes += promotionCode.code
    }
    return codes
  }, '')
}

export const promoHasAdjustmentSelector = (state: RootReducerState) => state.order.cart?.x_promoHasAdjustment

/*export const orderDetailsSelector = (state: RootReducerState):any => {
  const orderData = state.orderDetails?.data || {}
  const firstKey = Object.keys(orderData).length > 0 ? head(Object.keys(orderData)) : undefined
  if (firstKey) {
    // retrieving value of the first key
    return orderData[firstKey]
  }
}*/
export const orderDetailsSelector = (state: RootReducerState): IOrderDetails | null => state.order.orderDetails
export const shippingAddressIdSelector = (state: RootReducerState) => {
  return state.order.orderItems.find(order => !!order)?.addressId
}

export const paymentInstructionSelector = (state: RootReducerState) => {
  return state.order.cart?.paymentInstruction
}

export const paymentInstructionIdSelector = (state: RootReducerState) => {
  return state.order.cart?.paymentInstruction?.find(instruction => !!instruction)?.piId
}

export const isRXProductsLimitExceededSelector = (state: RootReducerState) => {
  return state.order.isRXProductsLimitExceeded
}

export const parsedOrderItemsSelector = (state: RootReducerState): ParsedOrderItemsMapByItemType | undefined => {
  return state.order.parsedOrderItems
}

export const isRxSkippedUploadSteps = (state: RootReducerState) => {
  return state.site.currentSite?.xStoreCfg?.rxSkipPrescription || false
}
export const isClSkippedUploadSteps = (state: RootReducerState) => {
  return state.site.currentSite?.xStoreCfg?.clSkipPrescription || false
}

export const userPrescriptionsMacroGroupSelector = (state: RootReducerState): PrescriptionMacroGroup[] => {
  return state.prescription.usePrescription.prescriptionMacroGroups
}

export const isSamePrescriptionSelectedSelector = (
  state: RootReducerState,
  prescriptionGroupIndex: number
): boolean => {
  return state.prescription.usePrescription.prescriptionMacroGroups[prescriptionGroupIndex]?.isSamePrescriptionSelected
}

export const userPrescriptionsSelectedItemSelector = (
  state: RootReducerState,
  prescriptionGroupIndex: number
): number => {
  return state.prescription.usePrescription.prescriptionMacroGroups[prescriptionGroupIndex]?.selectedItemIndex
}

export const userPrescriptionsSelectedGroupSelector = (state: RootReducerState): number => {
  return state.order.usePrescription.selectedMacroIndex
}

export const userPrescriptionFormDataSelector = (state: RootReducerState): PrescriptionFormData | undefined => {
  return state.order.usePrescription.prescriptionFormData
}

export const reorderIdSelector = (state: RootReducerState): string | undefined => state.order.reorderId

export const reorderInfoSelector = (state: RootReducerState): IReorderInfo | undefined => state.order.reorderInfo

export const selectedShipModeCodeSelector = (state: RootReducerState): string | undefined =>
  state.order.selectedShipModeCode

/*export const selectedItemSelector = (
  state: RootReducerState,
  prescriptionGroupIndex: number
): OrderItem[] | OrderItemWithRoxProps[] | null => {
  return state.prescription.usePrescription.prescriptionMacroGroups[prescriptionGroupIndex]?.orderItems
}

export const userPrescriptionsSelectedGroupSelector = (state: RootReducerState): number => {
  return state.prescription.usePrescription.selectedMacroIndex
}

export const userPrescriptionFormDataSelector = (state: RootReducerState): PrescriptionFormData | undefined => {
  return state.prescription.usePrescription.prescriptionFormData
}

export const userPrescriptionFileSelector = (state: RootReducerState) => {
  return state.prescription.usePrescription.prescriptionFile
}

export const isUploadingPrescriptionDetailsSelector = (state: RootReducerState): boolean => {
  return state.prescription.isUploadingPrescriptionDetails
}

export const isUploadingPrescriptionFileSelector = (state: RootReducerState): boolean => {
  return state.prescription.isUploadingPrescriptionFile
}

export const isFetchingFilePreviewSelector = (state: RootReducerState): boolean => {
  return state.prescription.isFetchingFilePreview
}
*/
