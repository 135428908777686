import { RootReducerState } from '@redux/rootReducer'

export const productTypeSelector = (state: RootReducerState) => state.product.productType
export const productContactLensesDataSelector = (state: RootReducerState) => state.product.contactLensesData
export const productAddToCartEnabledSelector = (state: RootReducerState) => state.product.addToCartEnabled
export const addedToCartSuccessSelector = (state: RootReducerState) => state.success.link
export const addContactLensesToCartErrorSelector = (state: RootReducerState) =>
  state.product.addContactLensesToCartError
export const addCLAccessoriesToCartErrorSelector = (state: RootReducerState) =>
  state.product.addCLAccessoriesToCartError
export const addSubscriptionToCartErrorSelector = (state: RootReducerState) => state.product.addSubscriptionToCartError
export const moCoOrderedIdsFromPlp = (state: RootReducerState) => state.product.moCoOrderedIds
export const currentProductBundleSelector = (state: RootReducerState) => state.product.currentBundle

export const prescriptionDetailsSelector = (state: RootReducerState) => ({
  doctor: state.product.doctor,
  issue: state.product.issue,
})
