/**
 * The PrescriptionInfoContainer is used to display the prescription information in
 * different views, in some views the issue date is provided in UTC format and in
 * some views it is provided in string timestamp format. This utility function is
 * used to manage the issue date format.
 */
export const dateUtcToLocalTime = (date?: string) => {
  if (!date) {
    return null
  }

  const inputDate = new Date(date)

  if (isNaN(inputDate.getTime())) {
    const localeDate = new Date(+date)

    if (isNaN(localeDate.getTime())) {
      return null
    }

    return localeDate
  }

  return inputDate
}
