import { isEmpty, toNumber } from 'lodash-es'
import { PRODUCT_TYPES_KEYS } from '../../constants/product'
import DateService from '../../services/DateService'
import { Adjustment } from '../../types/order'
import { getBrand as getBrandAlgolia, getIsPolarized as getIsPolarizedAlgolia } from '@utils/productAttributesAlgolia'
import { getBrand, getIsPolarized } from '@utils/productAttributes'
import { IProduct } from '../../types/product'
import { Catentries } from '@redux/rootReducer'
import { ADJUSTMENT_USAGE, USER_SEGMENT_GUEST } from '@constants/common'

export type AlgoliaPrice = {
  listPrice: number | string
  offerPrice: number | string
  percentageDiscount?: number
  amountOfDiscount?: number
  currency?: string
  startDate: string
  endDate: string
  segment?: string
  badge?: string
  precedence: number
  priceListPrecedence: number
  futurePrices?: AlgoliaPrice[]
}

export type PartNumberAlgoliaPrice = Record<string, AlgoliaPrice | undefined>

export interface IProductPrice {
  initialPrice: number
  offerPrice: number
  currency: string
  discountAbsolute: number
  discountPercentage: number
}

export const parseNumber = (value: string | number | undefined): number => {
  if (typeof value === 'string') return parseFloat(value)
  if (typeof value === 'number') return value
  return 0
}

export const calculateOfferPrice = (offerPrice: number, adjustments: Adjustment[], quantity = 1): number => {
  if (!adjustments || adjustments.length === 0) {
    return offerPrice
  }
  const totalAdjustments = adjustments
    .filter(
      adjustment =>
        ['OrderItem', 'Order'].includes(adjustment?.displayLevel) &&
        adjustment?.usage?.toLocaleLowerCase() === ADJUSTMENT_USAGE.DISCOUNT
    )
    .reduce((accumulator, adjustment) => accumulator + parseFloat(adjustment?.amount ?? '0'), 0)
  return offerPrice + totalAdjustments / quantity
}

export const calculatePercentageDiscount = (initialPrice: number, offerPrice: number): number => {
  if (initialPrice === offerPrice) {
    return 0
  }

  const percentageDiscount = Math.round((1 - offerPrice / initialPrice) * 100)
  return percentageDiscount < 100 ? percentageDiscount : 100
}

export const calculateAbsoluteDiscount = (initialPrice: number, offerPrice: number): number => {
  if (initialPrice === offerPrice) {
    return 0
  }

  return initialPrice - offerPrice
}

export const getProductPrice = (
  price?: AlgoliaPrice,
  _quantity?: string[],
  adjustments?: Adjustment[],
  isSubscriptionItemsActive?: boolean
): IProductPrice => {
  const originalOfferPrice = isSubscriptionItemsActive ? price?.listPrice : price?.offerPrice
  const productQuantity = _quantity?.length ? toNumber(_quantity[0] !== '0' ? _quantity[0] : '1') : 1
  const initialPrice = parseNumber(price?.listPrice ?? 0)
  const offerPrice = calculateOfferPrice(parseNumber(originalOfferPrice ?? 0), adjustments || [], productQuantity)
  const discountAbsolute = calculateAbsoluteDiscount(initialPrice, offerPrice) / productQuantity
  const discountPercentage = calculatePercentageDiscount(initialPrice, offerPrice)
  const currency = price?.currency || ''
  return {
    initialPrice,
    offerPrice,
    currency,
    discountAbsolute,
    discountPercentage,
  }
}

const sortByPriceListByPriority =
  (customerSegments: string[]) => (a: AlgoliaPrice | undefined, b: AlgoliaPrice | undefined) => {
    if (!a || !b) {
      return 0
    }

    /**
     * Sorting rules:
     * 1. Sort by customer segment order - first available match wins!!!
     *    i.e. ['EarlyAccess', 'RegisteredUsers', 'Guest'] => EarlyAccess, then fallback to RegisteredUsers, then fallback to Guest
     * 2. Sort by priceListPrecedence
     * 3. Sort by precedence
     * 4. Sort by offerPrice
     **/

    if (
      customerSegments?.indexOf(a?.segment ?? USER_SEGMENT_GUEST) ===
      customerSegments?.indexOf(b?.segment ?? USER_SEGMENT_GUEST)
    ) {
      if (parseNumber(a.priceListPrecedence) === parseNumber(b.priceListPrecedence)) {
        if (parseNumber(a.precedence) === parseNumber(b.precedence)) {
          return parseNumber(a.offerPrice) - parseNumber(b.offerPrice)
        }
        return parseNumber(b.precedence) > parseNumber(a.precedence) ? 1 : -1
      }

      return parseNumber(b.priceListPrecedence) > parseNumber(a.priceListPrecedence) ? 1 : -1
    }
    return (
      customerSegments?.indexOf(a?.segment ?? USER_SEGMENT_GUEST) -
      customerSegments?.indexOf(b?.segment ?? USER_SEGMENT_GUEST)
    )
  }

export const getCatEntriesAlgoliaPrices = (
  customerSegment: string[],
  catentries?: Catentries
): PartNumberAlgoliaPrice => {
  if (catentries) {
    return Object.keys(catentries)?.reduce(
      (map, id) => (
        (map[catentries[id].partNumber] = determineAlgoliaPrice(catentries?.[id].x_price!, customerSegment)), map
      ),
      {} as PartNumberAlgoliaPrice
    )
  } else {
    return {} as PartNumberAlgoliaPrice
  }
}

// this is used in cart, checkout, payment and so on
export const getProductAlgoliaPrice = (
  customerSegment: string[],
  product?: IProduct | null
): PartNumberAlgoliaPrice => {
  if (product && product.x_price) {
    return {
      [product.partNumber]: determineAlgoliaPrice(product.x_price, customerSegment),
    }
  } else {
    return {} as PartNumberAlgoliaPrice
  }
}

export const determineAlgoliaPrice = (
  prices:
    | {
      [key: string]: AlgoliaPrice
    }
    | undefined,
  customerSegments: string[],
  productType?: string,
  isRoxable?: boolean,
  isRxOrder?: boolean,
  isPLP?: boolean
): AlgoliaPrice | undefined => {
  if (!prices || isEmpty(prices)) {
    return undefined
  }

  function getSegmentPriceLists(
    prices: { [x: string]: AlgoliaPrice },
    customerSegments: string[]
  ): { [key: string]: AlgoliaPrice } {
    const segmentPriceLists = {}
    Object.keys(prices).forEach(key => {
      if (customerSegments.includes(prices?.[key]?.segment ?? '')) {
        segmentPriceLists[key] = prices[key]
      } else if (Array.isArray(prices?.[key]?.futurePrices)) {
        const filteredFuturePricesBySegment = prices?.[key]?.futurePrices?.filter(futurePrice => {
          return customerSegments.includes(futurePrice.segment ?? '')
        })
        if (!isEmpty(filteredFuturePricesBySegment)) {
          segmentPriceLists[key] = {
            futurePrices: filteredFuturePricesBySegment,
          }
        }
      }
    })
    return segmentPriceLists
  }

  const segmentPriceLists = getSegmentPriceLists(prices, customerSegments)
  let filteredPriceLists: {
    [key: string]: AlgoliaPrice
  } = {}

  if (
    productType === PRODUCT_TYPES_KEYS.FRAMES ||
    (productType === PRODUCT_TYPES_KEYS.OPTICAL && isRoxable && !isRxOrder) ||
    (productType === PRODUCT_TYPES_KEYS.SUN && isRoxable && !isRxOrder) ||
    (!isRoxable && !isRxOrder) ||
    isPLP
  ) {
    filteredPriceLists = Object.keys(segmentPriceLists)
      .filter(key => key.indexOf('RX') == -1)
      .reduce((price, key) => {
        price[key] = prices[key]
        return price
      }, {})
  } else if (isRxOrder) {
    filteredPriceLists = Object.keys(segmentPriceLists)
      .filter(key => key.indexOf('RX') !== -1)
      .reduce((price, key) => {
        price[key] = prices[key]
        return price
      }, {})
  }

  // NOTE: if conditions above return no result, fall back to price lists available to your customer segment
  if (isEmpty(filteredPriceLists)) {
    filteredPriceLists = segmentPriceLists
  }

  let eligiblePrices = getFuturePrices(Object.values(filteredPriceLists)).filter(
    (price: AlgoliaPrice) =>
      DateService.isISODateValid(price.startDate, price.endDate) && parseNumber(price.offerPrice) > 0
  )
  if (!eligiblePrices.length) {
    // NOTE: fallback to default guest price lists if nothing matches for your current segments
    let guestPriceLists = getSegmentPriceLists(prices, [USER_SEGMENT_GUEST])
    if (
      productType === PRODUCT_TYPES_KEYS.FRAMES ||
      (productType === PRODUCT_TYPES_KEYS.OPTICAL && isRoxable && !isRxOrder) ||
      (productType === PRODUCT_TYPES_KEYS.SUN && isRoxable && !isRxOrder) ||
      (!isRoxable && !isRxOrder) ||
      isPLP
    ) {
      guestPriceLists = Object.keys(guestPriceLists)
        .filter(key => key.indexOf('RX') == -1)
        .reduce((price, key) => {
          price[key] = prices[key]
          return price
        }, {})
    } else if (isRxOrder) {
      guestPriceLists = Object.keys(guestPriceLists)
        .filter(key => key.indexOf('RX') !== -1)
        .reduce((price, key) => {
          price[key] = prices[key]
          return price
        }, {})
    }
    eligiblePrices = getFuturePrices(Object.values(guestPriceLists)).filter(
      (price: AlgoliaPrice) =>
        DateService.isISODateValid(price.startDate, price.endDate) && parseNumber(price.offerPrice) > 0
    )
  }

  let validPrice: AlgoliaPrice | undefined
  const sortedPrices = eligiblePrices.sort(sortByPriceListByPriority(customerSegments))
  validPrice = sortedPrices[0]

  return validPrice
}

const getFuturePrices = (pricesArray: AlgoliaPrice[]): AlgoliaPrice[] => {
  const allPrices = pricesArray.reduce((acc, price) => {
    const { futurePrices, ...rest } = price
    acc.push(rest)
    if (futurePrices?.length) {
      acc.push(...getFuturePrices(futurePrices))
    }
    return acc
  }, [] as AlgoliaPrice[])
  if (allPrices.length === 0 && pricesArray.length > 0) {
    allPrices.push(pricesArray[0])
  }
  return allPrices
}

/**
 * @deprecated
 */
const getAllPricesByCustomerSegment = (pricesArray: AlgoliaPrice[], customerSegment: string): AlgoliaPrice[] => {
  const segmentPrices = pricesArray.reduce((acc, price) => {
    if (price.segment === customerSegment) {
      const { futurePrices, ...rest } = price
      acc.push(rest)
      if (futurePrices?.length) {
        acc.push(...getAllPricesByCustomerSegment(futurePrices, customerSegment))
      }
    }
    return acc
  }, [] as AlgoliaPrice[])

  // If no matched prices were found, return the first price from the original array
  if (segmentPrices.length === 0 && pricesArray.length > 0) {
    segmentPrices.push(pricesArray[0])
  }

  return segmentPrices
}

export const shouldShowAbsoluteDiscount = (price: AlgoliaPrice | undefined): boolean => {
  if (!price?.badge) {
    return false
  }

  return price.badge.indexOf('%') == -1
}
