import { FrameObject, LensObject } from '../types/rxConfigurator'
import { TFunction } from 'next-i18next'
import { localStorageUtil } from '../foundation/utils/storageUtil'
import { RX_CONFIGURATOR_ENABLED_FLOWS, RX_UPLOAD_SUPPORTED_FORMATS } from './prescription'

export const RX_CONFIGURATOR_DOM_ID = 'rxcApp'
export const RX_STYLE_ID = 'rxcCss'
export const RX_SCRIPT_ID = 'rxcJs'
export const RX_SAVED_LENS_DATA_KEY = 'rxSavedLensData' // localStorage key for saved lens data from RX configurator
export const RX_PRESCRIPTION_OBJECT_KEY = 'prescriptionObject' // localStorage key for prescription object
export const RX_FLOW_NAME_SUNGLASSES = 'CL_SUN'

export const RX_CONFIG = {
  selector: `#${RX_CONFIGURATOR_DOM_ID}`,
  actionsModule: {
    genericSaveLensSelection: (frame: FrameObject, lens: LensObject) => {
      if (lens) {
        localStorageUtil.set(RX_SAVED_LENS_DATA_KEY, {
          ...lens,
          _frameUpc: frame.upc,
        })
      }
    },
  },
  loginModule: {
    isLoggedIn: function () {
      return false
    },
    login: function (callback) {
      callback(false)
    },
  },
  prescriptionModule: {
    prescriptionType: 'FULL',
    prescriptionFlows: RX_CONFIGURATOR_ENABLED_FLOWS,
    fileExtensions: RX_UPLOAD_SUPPORTED_FORMATS,
    activateDistanceAndReading: true,
    hideMoreOptions: true,
    enablePrismComment: false,
    enablePrism: false,
    maxFileSize: 10,
    mandatory: true,
    saveToMyAccount: function (prescriptionObject) {
      return prescriptionObject
    },
    retrieveFromMyAccount: function () {
      return new Promise(function (_, reject) {
        reject(null)
      })
    },
    loadPrescription: function () {
      return new Promise(function (_, reject) {
        reject(null)
      })
    },
    clearPrescription: function () {
      try {
        localStorageUtil.remove(RX_PRESCRIPTION_OBJECT_KEY)
        return true
      } catch (e) {
        return false
      }
    },
    loadExtendedPrescription: function (_requestObject) {
      return new Promise(function (_, reject) {
        reject(null)
      })
    },
    clearExtendedPrescription: function (_requestObject) {
      return new Promise((resolve, reject) => {
        try {
          localStorageUtil.set(RX_PRESCRIPTION_OBJECT_KEY, _requestObject)
          resolve({})
        } catch (e) {
          reject({})
        }
      })
    },
  },
  currencyFormat: {
    thousandSeparator: ',',
    decimalSeparator: '.',
    decimalScale: 2,
  },
}

function buildLearnMoreModal(title: any, message: any) {
  return `
  <div class="PrescriptionModal__container" style='min-height:220px'>
    <div class="PrescriptionModal__container__closeContainer">
      <a class="PrescriptionModal__container__close"></a>
    </div>
    <div class="PrescriptionModal__container__title">
   ${title}
   </div>
    <div class="PrescriptionModal__showPDModalBody">
      <div class="PrescriptionModal__container__bodyText">
        ${message}
      </div>
    </div>
  </div>
  `
}

export const HOW_TO_READ_YOUR_PRESCRIPTION_CONTENT_KEY = 'HowToReadPrescription'
export const HOW_TO_READ_YOUR_PRESCRIPTION = (t: TFunction) =>
  buildLearnMoreModal(
    t('PrescriptionConfigurator.Labels.HowToReadYourPrescription.Title'),
    t('PrescriptionConfigurator.Labels.HowToReadYourPrescription.Description')
  )

export const PUPILLARY_DISTANCE_CONTENT_KEY = 'PupillaryDistance'
export const PUPILLARY_DISTANCE = (t: TFunction) =>
  buildLearnMoreModal(
    t('PrescriptionConfigurator.Labels.PupillaryDistance.Title'),
    t('PrescriptionConfigurator.Labels.PupillaryDistance.Description')
  )
