import { ICategory } from '@features/category/query'
import { PRODUCT_TYPES_KEYS, PRODUCT_TYPES_MAP, SUGGESTED_PRODUCT_FAMILY } from '../constants/product'
import Log from '../services/Log'

import { Attachment, ClusterProduct, PictureType, ProductImageUsage, ProductType, IProduct } from '../types/product'
import { getIsChildFrame, getNormalizedProductType, getProductType } from './productAttributes'
import config from '@configs/config.base'
import { OrderItem } from '@typesApp/order'
import storeUtil from './storeUtil'

// it normalizes the product type string by using the PRODUCT_TYPES_MAP
export const normalizedProductType = (pt: string) => PRODUCT_TYPES_MAP[pt?.toLowerCase()]

// takes a product type string and check whether it is a sun product
export const isSun = (pt: string) => normalizedProductType(pt) === PRODUCT_TYPES_KEYS.SUN

// takes a product type string and check whether it is a contact lenses product
export const isContactLenses = (pt: string) => normalizedProductType(pt) === PRODUCT_TYPES_KEYS.CONTACT_LENSES

// takes a product type string and check whether it is an optical product
export const isOptical = (pt: string) => normalizedProductType(pt) === PRODUCT_TYPES_KEYS.OPTICAL

// takes a product type string and check whether it is a frames product
export const isFrames = (pt: string) => normalizedProductType(pt) === PRODUCT_TYPES_KEYS.FRAMES

// takes a product type string and check whether it is an accessories product
export const isAccessories = (pt: string) => normalizedProductType(pt) === PRODUCT_TYPES_KEYS.ACCESSORIES

// takes a product type string and check whether it is a contact lenses accessories product
export const isCLAccessories = (pt: string) =>
  normalizedProductType(pt) === PRODUCT_TYPES_KEYS.CONTACT_LENSES_ACCESSORIES

// takes a product type string and check whether it is an electronics product
export const isElectronics = (pt: string) => normalizedProductType(pt) === PRODUCT_TYPES_KEYS.ELECTRONICS

// takes a Product object and check whether it is a sun product
export const isSunProduct = p => getNormalizedProductType(p) === PRODUCT_TYPES_KEYS.SUN

//takes a Product object and check whether it is a contact lenses accessories product
export const isClAccessoriesProduct = p => getNormalizedProductType(p) === PRODUCT_TYPES_KEYS.CONTACT_LENSES_ACCESSORIES

// takes a Product object and check whether it is a contact lenses product
export const isContactLensesProduct = p => getNormalizedProductType(p) === PRODUCT_TYPES_KEYS.CONTACT_LENSES

// takes a Product object and check whether it is an optical product
export const isOpticalProduct = p => getNormalizedProductType(p) === PRODUCT_TYPES_KEYS.OPTICAL

// takes a Product object and check whether it is a frames product
export const isFramesProduct = p => getNormalizedProductType(p) === PRODUCT_TYPES_KEYS.FRAMES

// takes a Product object and check whether it is an accessories product
export const isAccessoriesProduct = p => getNormalizedProductType(p) === PRODUCT_TYPES_KEYS.ACCESSORIES

// takes a Product object and check whether it is a contact lenses accessories product
export const isCLAccessoriesProduct = p => getNormalizedProductType(p) === PRODUCT_TYPES_KEYS.CONTACT_LENSES_ACCESSORIES

// takes a Product object and check whether it is an electronics product
export const isElectronicsProduct = p => getNormalizedProductType(p) === PRODUCT_TYPES_KEYS.ELECTRONICS

//FORMATTED PRODUCT METHODS

// takes a product type string and check whether it is a sun product
export const isFormattedProductSun = (pt: string) => normalizedProductType(pt) === PRODUCT_TYPES_KEYS.SUN

// takes a product type string and check whether it is a contact lenses product
export const isFormattedProductContactLenses = (pt: string) =>
  normalizedProductType(pt) === PRODUCT_TYPES_KEYS.CONTACT_LENSES

// takes a product type string and check whether it is an optical product
export const isFormattedProductOptical = (pt: string) => normalizedProductType(pt) === PRODUCT_TYPES_KEYS.OPTICAL

// takes a product type string and check whether it is a frames product
export const isFormattedProductFrames = (pt: string) => normalizedProductType(pt) === PRODUCT_TYPES_KEYS.FRAMES

// takes a product type string and check whether it is an accessories product
export const isFormattedProductAccessories = (pt: string) =>
  normalizedProductType(pt) === PRODUCT_TYPES_KEYS.ACCESSORIES

// takes a product type string and check whether it is an electronics product
export const isFormattedProductElectronics = (pt: string) =>
  normalizedProductType(pt) === PRODUCT_TYPES_KEYS.ELECTRONICS

// takes a Product object and check whether it is a sun product
export const isFormattedProductSunProduct = (pt: string) => normalizedProductType(pt) === PRODUCT_TYPES_KEYS.SUN

// takes a Product object and check whether it is a contact lenses product
export const isFormattedProductContactLensesProduct = (pt: string) =>
  normalizedProductType(pt) === PRODUCT_TYPES_KEYS.CONTACT_LENSES

// takes a Product object and check whether it is an optical product
export const isFormattedProductOpticalProduct = (pt: string) => normalizedProductType(pt) === PRODUCT_TYPES_KEYS.OPTICAL

// takes a Product object and check whether it is a frames product
export const isFormattedProductFramesProduct = (pt: string) => normalizedProductType(pt) === PRODUCT_TYPES_KEYS.FRAMES

// takes a Product object and check whether it is an accessories product
export const isFormattedProductAccessoriesProduct = (pt: string) =>
  normalizedProductType(pt) === PRODUCT_TYPES_KEYS.ACCESSORIES

// takes a Product object and check whether it is a contact lenses accessories product
export const isFormattedProductCLAccessories = (pt: string) =>
  normalizedProductType(pt) === PRODUCT_TYPES_KEYS.CONTACT_LENSES_ACCESSORIES

/**
 * Get key to retrieve brand information in @components/BrandIcon/brandList.ts
 *
 * @param {string} brand
 * @returns {string} brand key based on brand name without non-alphanumeric characters
 */
export const getBrandKey = (brand: string): string => {
  return brand.toLowerCase().replace(/[^0-9a-z]/gi, '')
}

export const getCurrentProductItem = (partNumber: string, cluster?: IProduct[]): IProduct | null => {
  try {
    const product = cluster
      ?.map(p => {
        let item: IProduct | null

        if (isContactLensesProduct(p) || isCLAccessoriesProduct(p)) {
          item = p.partNumber === partNumber ? p : null
        } else {
          item = !!p.items ? p.items.find((item: IProduct) => item.partNumber === partNumber) || null : null
        }

        return item
      })
      .find(item => !!item)
    const itemBean = !!product ? getCurrentProductItemBean(product) : null
    return itemBean || product || null
  } catch (e: any) {
    Log.error(`Error retrieving current product item: ${e}`)
    return null
  }
}

export const productHasMTO = (mySite, product) => {
  return (
    storeUtil.isMTOEnabled(mySite) &&
    (mySite?.xStoreCfg?.MTO_ENABLED_UPC?.toLowerCase().split(',').includes(product?.partNumber.toLowerCase()) ||
      !mySite?.xStoreCfg?.MTO_ENABLED_UPC ||
      mySite.xStoreCfg?.MTO_ENABLED_UPC === null ||
      mySite?.xStoreCfg?.MTO_ENABLED_UPC.length === 0)
  )
}

export const getCurrentProductItemFromProductData = (
  partNumber: string,
  productData?: IProduct | null
): IProduct | null | undefined => {
  try {
    const product = productData?.cluster
      ?.map(p => {
        if (
          isFormattedProductContactLenses(p.productAttributes['PRODUCT_TYPE']) ||
          isFormattedProductCLAccessories(p.productAttributes['PRODUCT_TYPE'])
        ) {
          return p.partNumber === partNumber ? p : null
        } else {
          return !!p.items ? p.items.find((item: IProduct) => item.partNumber === partNumber) : null
        }
      })
      .find(item => !!item)

    return product
  } catch (e: any) {
    Log.error(`Error retrieving current product item: ${e}`)
    return null
  }
}

export const getCurrentProductItemByUniqId = (id: string, clusters?: IProduct[]): IProduct | null => {
  try {
    const product =
      clusters
        ?.map((p): IProduct | null => {
          return !!p.items ? p.items.find((item: IProduct) => item.uniqueID === id) || null : null
        })
        .find(item => !!item) || null
    return product
  } catch (e: any) {
    Log.error(`Error retrieving current product item: ${e}`)
    return null
  }
}

export const getCurrentProductItemBean = (product?: IProduct): IProduct | null => {
  try {
    const itemBean = product?.catalogEntryTypeCode === 'ProductBean' ? product?.items?.find(item => !!item) : product
    return itemBean || null
  } catch (e: any) {
    Log.error(`Error retrieving item bean: ${e}`)
    return null
  }
}

export const getCurrentCluster = (partNumber: string, cluster?: ClusterProduct[]): ClusterProduct[] | null => {
  return (
    cluster?.filter(cluster => {
      return !!cluster?.items?.find(item => item.partNumber === partNumber)
    }) || null
  )
}

export const getMerchandisingProducts = (partNumber: string, cluster?: IProduct[]) => {
  let merchandising: any
  const product = cluster
    ?.map(p => {
      if (isContactLensesProduct(p)) {
        if (p.partNumber === partNumber) merchandising = p?.merchandisingAssociations
      } else {
        p?.items?.map((item: IProduct) => {
          if (item.partNumber === partNumber) merchandising = p?.merchandisingAssociations
        })
      }

      return merchandising
    })
    .find(item => !!item)

  return product || null
}

export const getSuggestedProducts = (merchandisingProducts: any) => {
  return merchandisingProducts?.filter(
    item =>
      item.associationType?.toString().toLowerCase() === SUGGESTED_PRODUCT_FAMILY.X_SELL_FAMILY ||
      item.associationType?.toString().toLowerCase() === SUGGESTED_PRODUCT_FAMILY.CL_FAMILY
  )
}

export const generateProductImagePath = (
  url: string,
  usage: ProductImageUsage,
  product?: IProduct,
  pictureType?: PictureType,
  attachments?: Attachment[],
  sequence?: string,
  isFramesProduct?: boolean
) => {
  const attachment =
    attachments &&
    attachments.find(attachment => {
      return !isAccessoriesProduct(product)
        ? attachment.usage?.toLowerCase() === usage.toLowerCase() &&
            (pictureType ? attachment.name.toLowerCase() === pictureType.toLowerCase() : true) &&
            (sequence
              ? attachment.sequence.toLowerCase() === sequence.toLowerCase() ||
                (isFramesProduct && attachment.sequence.toLowerCase() === '1.0')
              : true)
        : (pictureType ? attachment.name.toLowerCase() === pictureType.toLowerCase() : true) &&
            (sequence ? attachment.sequence.toLowerCase() === sequence.toLowerCase() : true)
    })

  const url404 = config.publicUrl + 'images/common/404.svg'
  const imagePath = attachment ? url + attachment.attachmentAssetPathRaw : url404
  return imagePath
}

export const getCategoryIdentifier = (productDetails: any) => {
  // it tasks the parentCatalogGroupID and split flat destroy it
  // in order to take the last category id found

  let categoryIdentifier = ''
  const parentCatalogGroupID = productDetails[0].parentCatalogGroupID
  let ids: string[]
  if (Array.isArray(parentCatalogGroupID)) {
    ids = parentCatalogGroupID.map(el => el.split('/')).flat()
  } else {
    ids = parentCatalogGroupID?.split('/')
  }
  if (ids && ids.length > 0) {
    categoryIdentifier = ids[ids.length - 1]
  }
  return categoryIdentifier
}

export const getParentCatalogGroupId = (category?: ICategory[] | null) => {
  if (category) {
    if (Array.isArray(category[category.length - 1]?.parentCatalogGroupID)) {
      return category[category.length - 1]?.parentCatalogGroupID?.[1]
        .toString()
        .replaceAll('/', ',')
        .slice(1)
        .split(',')
    } else {
      return category[category.length - 1]?.parentCatalogGroupID?.toString().replaceAll('/', ',').slice(1).split(',')
    }
  }
  return null
}

/**
 * Checks whether product type is one of the eyeglasses products
 *
 * @param {ProductType} productType
 * @returns {boolean}
 */
export const isGlassesOrSunglasses = (productType?: ProductType | null): boolean => {
  switch (productType) {
    case PRODUCT_TYPES_MAP.optical:
    case PRODUCT_TYPES_MAP.frames:
    case PRODUCT_TYPES_MAP.sun:
      return true
    default:
      return false
  }
}

/**
 * Checks whether product type is one of eligible for Virtual Mirror
 *
 * @param {IProduct | null | undefined} product
 * @returns {boolean}
 */
export const isVirtualMirrorEligible = (product: IProduct | null | undefined): boolean => {
  if (product == null || !!getIsChildFrame(product)) return false

  const productType = getProductType(product)

  const eligibleTypes: Partial<ProductType>[] = [
    PRODUCT_TYPES_KEYS.FRAMES,
    PRODUCT_TYPES_KEYS.SUN,
    PRODUCT_TYPES_KEYS.OPTICAL,
  ]
  return eligibleTypes.includes(productType?.toLowerCase() as ProductType)
}

export const isMtoProduct = (product: IProduct) => !!product.productAttributes?.['CL_MTO_ENABLED']

export const getProductsPartNumbers = (items: OrderItem[] | IProduct[]): string[] => {
  if (items?.length) {
    const productPartNumbers = items.map(item => {
      const isContactLens = isContactLensesProduct(item)

      if (isContactLens) {
        return item['relationship.item.id'] || item.productId || ''
      }
      return item.partNumber
    })

    // ensures that we only return unique product numbers
    return [...new Set(productPartNumbers)]
  }

  return []
}
